import React, { FunctionComponent, useEffect, Suspense, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import objectAssign from 'object-assign';
import authService from '../services/authService';
import { UserReducerState } from '../../types/types';
import { addNotification } from '../../shared/reducers/notifications/actionTypes';
import { setProfile } from '../../shared/reducers/user/actionTypes';
import { setBranding } from '../../shared/reducers/branding/actionTypes';
import { setTenantData } from '../../shared/reducers/tenant/actionTypes';
import SpinnerLoad from './common/spinnerLoad/spinnerLoad';
import PageFrame from './pageFrame/pageFrame';
import { AxiosResponse } from 'axios';
import HomePage from './homePage/homePage';
import CustomLayoutView from './customLayout/customLayoutView';
import PnServiceWorker from './common/pushNotification/pnServiceWorker';
import TileView from '../components/content/tileView';
import { fetchMenuItems } from '../../shared/reducers/branding/actionTypes';
import { connect } from 'react-redux';
import { Chat } from '../pages/Chat/Chat';
import { Connect } from '../pages/Connect/Connect';
import { People } from '../pages/Connect/subPages/People/People';
import { Locations } from '../pages/Connect/subPages/Locations/Locations';
import { Search } from '../pages/Search/Search';
import { SocialPost } from '../pages/SocialPost/SocialPost';
const AwardsPage = React.lazy(() => import('./awardsPage/awardsPage'));
const FeedPage = React.lazy(() => import('./feedPage/feedPage'));
const LocationsDetailsPage = React.lazy(() => import('./connectPage/locationPage/locationDetailsPage'));
const ContactDetailsPage = React.lazy(() => import('./connectPage/contactPage/contactDetails'));

type AppWrapperProps = {
    fetchMenuItems: any;
};

const AppWrapper: FunctionComponent<AppWrapperProps> = (props) => {
    const { t } = useTranslation();
    const location = useLocation();
    const dispatch = useDispatch();
    const [permission, setPermission] = useState('');
    // Make user, tenant and branding info request

    useEffect(() => {
        const isInChatPage = location.pathname.startsWith('/chat');
        if (!isInChatPage && window.Echo && Object.keys(window.Echo.connector.channels).length > 0) {
            window.Echo.leaveAllChannels();
        }
    }, [location.pathname]);

    useEffect(() => {
        authService.getUserTenantAndBrandingInfo(
            (resp: AxiosResponse) => {
                const userResp = resp.data.user;
                const brandingResp = resp.data.branding;
                const tenantResp = resp.data.tenant;

                const userData: UserReducerState = objectAssign({}, userResp);
                authService.storeUserInfo(JSON.stringify(userData));
                dispatch(setProfile(userData));
                dispatch(setBranding(brandingResp));
                dispatch(setTenantData(tenantResp));
            },
            () => {
                dispatch(
                    addNotification({
                        label: `User, Tenant and Branding`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
            }
        );
        props.fetchMenuItems();
    }, []);

    const RouteComponent = () => {
        let targetUrl = !location.pathname || location.pathname === '/' ? '/home' : location.pathname;
        targetUrl += location.search;
        window.location.href = targetUrl;

        return (
            <SpinnerLoad
                className={'position-absolute bg-white place-at-top d-flex justify-content-center align-items-center h-100 w-100 top-0 left-0'}
            />
        );
    };

    return (
        <PageFrame {...props} setPermission={setPermission}>
            <PnServiceWorker permission={permission} />
            <Suspense fallback={<div />}>
                <Routes>
                    <Route path={'awards/*'} element={<AwardsPage />} />
                    <Route path={'feed'} element={<FeedPage />} />
                    <Route path={'social/post/:id'} element={<SocialPost />} />
                    <Route path={'home'} element={<HomePage />} />
                    <Route path={'custom/:type/:id'} element={<CustomLayoutView />} />
                    <Route path={'tile/:type/:id'} element={<TileView />} />
                    <Route path={'connect'} element={<Connect />}>
                        <Route index path={'people'} element={<People />} />
                        <Route path={'people/:id'} element={<People />} />
                        <Route path={'people/:id/:mode'} element={<People />} />
                        <Route path={'locations'} element={<Locations />} />
                        <Route path={'locations/:id'} element={<Locations />} />
                    </Route>
                    <Route path={'location/:id?'} element={<LocationsDetailsPage />} />
                    <Route path={'chat'} element={<Chat />} />
                    <Route path={'chat/:id'} element={<Chat />} />
                    <Route path={'chat/new'} element={<Chat />} />
                    <Route path={'search'} element={<Search />} />
                    <Route path='/' element={<RouteComponent />} />
                </Routes>
            </Suspense>
        </PageFrame>
    );
};

const mapDispatchToProps = (dispatch: any) => {
    return {
        fetchMenuItems: () => dispatch(fetchMenuItems()),
    };
};

export default connect(null, mapDispatchToProps)(AppWrapper);

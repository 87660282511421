import { useQuery } from "react-query";
import Client from "../../services/axiosService";

/**
 * @description: Fetches social post from the API and returns the data as a promise
 * @param searchQuery | string
 * @param page 
 * @returns 
 */
const fetchSocialPost = async (id: number) => {
    const response = await Client().request({
        url: `${process.env.REACT_APP_CMS_API_URL}/react/feed/${id}`,
        method: "get",
    })

    return response.data;
}

/**
 * @description: Fetches individual social post from the API and returns the data
 * @param debouncedSearchQuery 
 * @returns 
 */
export const useSocialPostQuery = (id: number) => {
    return useQuery(
        ['socialPost', id],
        () => fetchSocialPost(id),
        {
            refetchOnWindowFocus: false,
            retry: 0,
        }
    );
}
import React, { FunctionComponent, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import classNames from "classnames";
import objectAssign from "object-assign";
import NotificationListItem from "./notificationsListItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { NotificationItemType, NotificationItem } from "../../../types/types";
import notificationsService from "../../services/notificationsService";
import { AxiosResponse } from "axios";
import { addNotification } from "../../../shared/reducers/notifications/actionTypes";

import Scrollbar from "../common/scrollbar/scrollbar";
import SpinnerLoad from "../common/spinnerLoad/spinnerLoad";

type NotificationListProps = {
  onBackBtn?: Function;
  containerClassName?: string;
  itemClassName?: string;
  setNotificationCount?: Function;
  notifications: NotificationItemType | undefined;
  setNotifications: React.Dispatch<React.SetStateAction<NotificationItemType | undefined>>;
  notificationsLoading: boolean;
  setNotificationsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  getNotifications: Function;
};

const NotificationList: FunctionComponent<NotificationListProps> = (props) => {
  const {onBackBtn, containerClassName, itemClassName, setNotificationCount, notifications, setNotifications, notificationsLoading, setNotificationsLoading, getNotifications} = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const backBtnCallback = () => {
    if (props.onBackBtn) props.onBackBtn();
  };

  const itemReadChangeHandler = (id: number, isRead: boolean) => {
    const step = isRead ? -1 : 1;
    const unreadCount =
      notifications && notifications.meta
        ? notifications.meta.total_unread + step
        : step;
    const newMeta = objectAssign({}, notifications && notifications.meta, {
      total_unread: unreadCount,
    });

    const newArr = [...(notifications ? notifications.data : [])];
    let item = newArr.filter((item: NotificationItem) => item.id === id)[0];
    item.is_read = isRead;

    setNotifications(
      objectAssign({}, notifications, { data: newArr, meta: newMeta })
    );
    if (props.setNotificationCount) props.setNotificationCount(unreadCount);
  };

  const markAllRead = () => {
    const oldNotifications = JSON.stringify(
      notifications && notifications.data
    );
    // Set item as read before the request, as the request takes time to process and there's a feeling like it's glitched
    let newNotifications = [...(notifications ? notifications.data : [])];
    newNotifications.map((item) => (item.is_read = true));
    setNotifications(
      objectAssign({}, notifications, { data: newNotifications })
    );
    notificationsService.markAllAsRead(
      () => {
        if (props.setNotificationCount) props.setNotificationCount(0);
      },
      () => {
        // If the request failed - set items back
        setNotifications(
          objectAssign({}, notifications, {
            data: JSON.parse(oldNotifications),
          })
        );
        dispatch(
          addNotification({
            label: `Read All`,
            text: t("general.errors.errorLoadingData"),
            type: "danger",
          })
        );
      }
    );
  };

  const scrollCapture = (e: any) => {
    if (e.scrollTop + e.clientHeight >= e.scrollHeight) {
      getNotifications();
    }
  };

  return (
    <div
      className={classNames(
        "bg-white text-secondary pt-3 d-flex flex-column",
        props.containerClassName
      )}
    >
      <h1
        className={classNames("ms-3 mb-2 font-weight-600 font-24", {
          "c-pointer": props.onBackBtn,
        })}
        onClick={backBtnCallback}
      >
        {props.onBackBtn && (
          <FontAwesomeIcon className={"me-3"} icon={faAngleLeft} />
        )}
        <span>{t("partials.menus.notifications")}</span>
      </h1>
      <h4
        className={
          "text-end c-pointer font-16 mt-4 me-3 text-primary font-weight-bold"
        }
        onClick={markAllRead}
      >
        {t("partials.menus.mark as read")}
      </h4>

      <Scrollbar
        onScroll={scrollCapture}
        className={"bg-danger flex-grow bg-white"}
      >
        {notifications &&
          notifications.data.map((item: NotificationItem) => (
            <NotificationListItem
              key={item.id}
              item={item}
              onReadChange={itemReadChangeHandler}
            />
          ))}
        {notificationsLoading && (
          <SpinnerLoad className="w-100 text-center mt-3" />
        )}
      </Scrollbar>
    </div>
  );
};

export default NotificationList;

import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import { IconCard } from '../../IconCard/iconCard';
import { Box, Typography } from '@mui/material';
import './socialPost/socialPostItem.scss';

type ItemHeaderProps = {
    headText: string;
    imgUrl: string;
    isImgRound?: boolean;
    iconClass?: string; // this can be a class for <em> or a file URL
    className?: string;
    time?: string;
    jobTitle?: string;
    iconClickCallback?: Function;
    contact_id: string;
};

const ItemHeader: FunctionComponent<ItemHeaderProps> = ({
    headText,
    imgUrl,
    isImgRound = true,
    iconClass,
    className,
    time,
    jobTitle,
    contact_id,
    iconClickCallback,
}) => {
    const navigate = useNavigate();
    const clickIcon = () => {
        if (iconClickCallback) iconClickCallback();
    };

    const navigateToUser = (contact_id: string) => {
        navigate(`/connect/people/${contact_id}`);
    };

    return (
        <Row
            data-testid='item-header'
            className={classnames('feed-item-header position-relative', { 'icon-class': iconClass && iconClass.indexOf('.') !== -1 }, className)}
        >
            <Col lg={11} md={10} sm={10} xs={10} className='d-flex align-items-center pt-2'>
                <IconCard
                    className='social-post-user-details '
                    icon={imgUrl}
                    sx={{ padding: '0px!important', cursor: 'pointer' }}
                    titleStyle={{
                        width: '100%',
                    }}
                    iconStyle={{
                        width: '62px',
                        height: '62px',
                    }}
                    handleClick={(e) => {
                        e.stopPropagation();
                        navigateToUser(contact_id);
                    }}
                >
                    <Box className='social-post-user-details-description'>
                        <Box className='user-details'>
                            <Typography sx={{ fontWeight: '600', fontSize: '16px', lineHeight: '1.2', '&:hover': {textDecoration: 'underline'} }}> {headText}</Typography>
                            <Typography sx={{ fontWeight: '400', fontSize: '13px', lineHeight: '1' }}>{jobTitle || ''}</Typography>
                            <Typography sx={{ fontWeight: '400', fontSize: '13px', lineHeight: '1.5' }}>{time || ''}</Typography>
                        </Box>
                    </Box>
                </IconCard>
            </Col>
            <Col lg={1} md={2} sm={2} xs={2} className='d-flex justify-content-end'>
                {iconClass && iconClass.indexOf('.') === -1 ? (
                    <em
                        className={classnames(`feed-item-icon text-light-black position-absolute fa-2x mt-1`, { 'c-pointer': clickIcon }, iconClass)}
                        data-testid='dot-button'
                        onClick={(e) => {
                            e.stopPropagation();
                            clickIcon();
                        }}
                    />
                ) : (
                    iconClass && <img src={iconClass} alt='Item Icon' style={{ height: '2.5rem' }} />
                )}
            </Col>
        </Row>
    );
};

export default ItemHeader;

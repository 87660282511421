import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { SideNavItem, BrandingReducerState } from '../../../../types/types';
import { useNavigate } from 'react-router-dom';
import { SubNavMenu } from '../../../modules/SubNavMenu/SubNavMenu';
import { SubNavLinks }                                         from '~/types/interface/SubNavLinks';
import { canNavigateToUrl, navigateToUrl, shouldOpenInNewTab } from '../../../utils/linkHandler';

type SideNavLinkProps = {
    item: SideNavItem;
    branding: BrandingReducerState;
    isActive: boolean;
    unreadThreads: number;
    isForMobileView?: boolean;
    displayChildrenDrawer?: boolean;
};

const SideNavLink: FunctionComponent<SideNavLinkProps> = ({ item, isActive, branding, unreadThreads, isForMobileView, displayChildrenDrawer }) => {
    const getBackColor = () => {
        return isActive ? branding.web_menubar_active_background : branding.web_menubar_background;
    };
    const getTextColor = () => {
        return isActive ? branding.web_menubar_active_foreground : branding.web_menubar_foreground;
    }

    const navigate = useNavigate();

    const [openDrawer, setOpenDrawer] = useState<boolean>(false);
    const [backgroundColor, setBackgroundColor] = useState(getBackColor());
    const [textColor, setTextColor] = useState(getTextColor());
    const { route_url } = item;

    useEffect(() => {
        setBackgroundColor(getBackColor());
        setTextColor(getTextColor());
    }, [isActive, branding]);

    const iconContainerRef = useRef<HTMLInputElement>(null);
    useEffect(() => {
        if (!iconContainerRef.current) return;
        const menuItemImages = iconContainerRef.current.querySelector('svg');
        if (!menuItemImages) return;
        // Clear all "fill" styles that exist
        menuItemImages.querySelectorAll('*').forEach((item) => {
            if (item.getAttribute('fill') !== 'none') item.setAttribute('fill', '');
            if (item.getAttribute('stroke')) {
                item.classList.add('svg-stroke-el');
            }
        });
        menuItemImages.style.visibility = 'visible';
    });

    const mouseOver = (e: React.MouseEvent) => {
        setBackgroundColor(branding.web_menubar_active_background);
        setTextColor(branding.web_menubar_active_foreground);
        displayChildrenDrawer && setOpenDrawer(true);
    };

    const mouseLeave = (e: React.MouseEvent) => {
        const classList = (e.relatedTarget as HTMLElement)?.classList;
        
        // Check whether we're mousing over a scrollbar or the navbar itself, and if so
        // don't action the event
        if (classList && (
          classList.contains('navbar')
          || classList.contains('container-fluid')
          || classList.contains('sub-nav-menu')
          || classList.contains('MuiListItem-gutters')
        )) {
            e.preventDefault();
            return;
        }

        setBackgroundColor(getBackColor());
        setTextColor(getTextColor());
        displayChildrenDrawer && setOpenDrawer(false);
    };

    const renderCustomIcon = () => {
        return { __html: item.custom_icon };
    };

    const handleKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleLinkNavigation()
        }
    };
    
    const handleLinkClick = () => {
        handleLinkNavigation()
    }
    
    const handleLinkNavigation = () => {
        if (canNavigateToUrl(route_url)) {
            navigateToUrl(route_url);
        } else {
            const isChat = route_url === '/messages' || route_url === '/chat';
            navigate(isChat ? '/chat' : route_url);
        }
    }

    return (
        <>
            <li className={`position-relative ${isForMobileView ? 'mobile-view-menu' : ''}`} data-testid='side-nav-link'>
                <a
                    id={`menu-${item.id}`}
                    data-testid='nav-link'
                    onClick={handleLinkClick}
                    onKeyDown={handleKeyDown}
                    tabIndex={0}
                    className={`nav-link ${isActive || (item.children != null && displayChildrenDrawer && openDrawer)  ? 'active' : ''} c-pointer d-block text-decoration-none pb-0`}
                    style={{
                        backgroundColor: backgroundColor,
                        color: textColor,
                        padding: '8px 0px 0px 0px',
                    }}
                    target={shouldOpenInNewTab(route_url) ? '_blank' : ''}
                    rel='noopener noreferrer'
                    onMouseEnter={mouseOver}
                    onMouseLeave={mouseLeave}
                >
                    <div className={'mb-1'}>
                        {item.custom_icon ? (
                            <div
                                data-testid='menu-icon'
                                className={'menu-image'}
                                ref={iconContainerRef}
                                dangerouslySetInnerHTML={renderCustomIcon()}
                            />
                        ) : (
                            <em className={item.icon_class} />
                        )}
                    </div>
                    {item.is_visible_title ? <span data-testid='menu-title'>{item.title}</span> : <div style={{ marginTop: '33px' }} />}
                    {unreadThreads !== 0 && (
                        <span className='unread-threads-count'>
                            <i className='fa fa-circle font-22 unread-icon'></i>
                            <span
                                data-testid='unread-thread-count'
                                className='unread-counter position-absolute fw-bold mx-0 d-flex justify-content-center align-items-center'
                                style={{ color: backgroundColor }}
                            >
                                {unreadThreads}
                            </span>
                        </span>
                    )}
                    <hr className='my-0 mt-2 w-60 mx-auto' />
                </a>
                {item.children != null && displayChildrenDrawer && openDrawer && (
                  <SubNavMenu
                    onMouseLeave={mouseLeave}
                    linksData={item.children as SubNavLinks[]}
                    drawerSx={{
                        marginLeft: '85px',
                        marginTop: '70px',
                        height: 'calc(100vh - 70px)',
                        overflowY: 'auto',
                        backgroundColor: branding.web_menubar_background,
                        filter: 'contrast(0.9)',
                    }}
                  />
                )}
            </li>
        </>
    );
};

export default SideNavLink;

export default {
  "sharePost": "Fangen Sie an zu teilen...",
  "what do you have to say": "Was hast du zu sagen",
  "emptyField": "Ein leerer Beitrag in sozialen Netzwerken kann nicht gespeichert werden.",
  "socialPost": "Sozialer Beitrag",
  "postedFail": "Der Beitrag in sozialen Netzwerken konnte nicht veröffentlicht werden. Bitte versuchen Sie es später erneut.",
  "mediaFail": "Beim Hochladen der Medien ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.",
  "close": "Schliessen",
  "mediaTooBig": "Einige Dateien haben die maximale Größe überschritten und konnten nicht hochgeladen werden. Die maximale Dateigröße ist",
  "mentionUsers": "Nutzer erwähnen",
  "mentionUsersFail": "Die erwähnten Benutzer konnten nicht abgerufen werden. Bitte versuchen Sie es später erneut.",
  "userUnavailable": "Benutzer nicht verfügbar",
  "postUnavailable": "Ups! Wir können den Beitrag, den Sie suchen, nicht finden.",

  "you": "Du",
  "single_you": "Du",

  "you_reposted_this": "hast dies repostet",
  "singular_reposted_this": "hat dies repostet",
  "we_reposted_this": "haben dies repostet",
  "they_reposted_this": "haben dies repostet",

  "and": "und",
  "more": "{{dynamicValue}} weitere",
  "users": "Neu gepostete Benutzer",
  "repostFailMessage": "Du hast dies bereits erneut gepostet",
  "repost": "Erneut posten",
  "repostWithYourThoughts": "Reposten Sie mit Ihren Gedanken",
  "shareYourThoughtsOnThisPost": "Teilen Sie Ihre Gedanken zu diesem Beitrag mit",
  "repostWithoutThoughts": "Teilen Sie den Beitrag von {{dynamicValue}}s mit Ihren Followern",
  "repostSuccessMessage": "Erfolgreich erneut gepostet."
};
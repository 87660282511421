const shouldOpenInSameTab = (linkUrl: string): boolean => {
    return linkUrl.startsWith('/page') || linkUrl.startsWith('/category')
      || linkUrl.startsWith('/custom') || linkUrl.startsWith('/section');
}

const shouldOpenInNewTab = (linkUrl: string): boolean => {
    return linkUrl.startsWith('http') || linkUrl.startsWith('/sp');
}

const canNavigateToUrl = (linkUrl: string): boolean => {
    return shouldOpenInSameTab(linkUrl) || shouldOpenInNewTab(linkUrl);
}

const navigateToUrl = (linkUrl: string): void => {
    if (shouldOpenInSameTab(linkUrl)) {
        window.location.href = linkUrl;
    } else if (shouldOpenInNewTab(linkUrl)) {
        window.open(linkUrl, '_blank');
    }
}
    
export {
    canNavigateToUrl,
    shouldOpenInSameTab,
    shouldOpenInNewTab,
    navigateToUrl
}

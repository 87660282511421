
/**
 * @description Format date time to human readable short time format with regards to local time zone (e.g. Jan 1, 12:34)
 * @param createdAt: string - date time string 
 * @returns: string - formatted date time string
 */
const formatShortDateTime = (createdAt: string, showYear = false) => {
    const date = new Date(createdAt);
    const monthText = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    // Get the browser's time zone offset in minutes
    const timeZoneOffset = date.getTimezoneOffset();
    // Adjust the date and time based on the time zone offset
    const browserDate = new Date(date.getTime() - timeZoneOffset * 60000);
    
    const day = browserDate.getDate();
    const hours = String(browserDate.getHours()).padStart(2, '0');
    const minutes = String(browserDate.getMinutes()).padStart(2, '0');
    const year = browserDate.getFullYear();

    return `${monthText} ${day}${showYear ? ` ${year}` : ''}, ${hours}:${minutes}`;
}



export {
    formatShortDateTime
}
export default {
  "sharePost": "Commencez à partager...",
  "what do you have to say": "Qu'est-ce que tu as à dire",
  "emptyField": "Impossible d'enregistrer une publication sociale vide.",
  "socialPost": "Publication sociale",
  "postedFail": "La publication sur les réseaux sociaux n'a pas pu être publiée, veuillez réessayer ultérieurement.",
  "mediaFail": "Une erreur s'est produite lors du téléchargement du contenu multimédia. Veuillez réessayer ultérieurement.",
  "close": "Fermer",
  "mediaTooBig": "Certains fichiers ont dépassé la taille maximale et n'ont pas pu être chargés. La taille maximale du fichier est",
  "mentionUsers": "Mentionnez les utilisateurs",
  "mentionUsersFail": "Impossible de récupérer les utilisateurs mentionnés, veuillez réessayer ultérieurement.",
  "userUnavailable": "Utilisateur non disponible",
  "postUnavailable": "Oups ! Nous ne pouvons pas trouver l'article que vous recherchez.",

  "you": "Vous",
  "single_you": "Vous",
  
  "you_reposted_this": "avez reposté ceci",
  "singular_reposted_this": "a reposté ceci",
  "we_reposted_this": "avez reposté ceci",
  "they_reposted_this": "ont reposté ceci",

  "and": "et",
  "more": "{{dynamicValue}} autres",

  "users": "Utilisateurs republiés",
  "repostFailMessage": "Tu as déjà reposté ceci",
  "repost": "Republier",
  "repostWithYourThoughts": "Republiez avec vos pensées",
  "shareYourThoughtsOnThisPost": "Partagez vos réflexions sur cet article",
  "repostWithoutThoughts": "Partagez la publication de {{dynamicValue}} avec vos abonnés",
  "repostSuccessMessage": "Republié avec succès."
};
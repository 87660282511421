export default {
  "referencePrompt": "Please enter your company reference",
  "referenceBackupPrompt": "Not sure what your reference is? Try your email address",
  "referencePlaceholder": "Enter your company reference or email",
  "please enter your user ID and password": "Please enter your user ID and password",
  "please enter user id": "Please enter your user id",
  "enter password": "Please enter your password",
  "forgot password": "Forgot password",
  "errors": {
    "domain name can't be empty": "Domain name can't be empty",
    "user name can't be empty": "User name can't be empty",
    "password can't be empty": "Password can't be empty",
    "incorrect user id or password": "Incorrect User ID or Password",
    "domain does not exist": "Domain '{{domain}}' does not exist",
    "account locked": "There were too many attempts, the account is now locked"
  },
  "hello": "Hello",
  "log in": "Log in",
  "new user": "New user",
  "create new account": "Create new account",
  "next": "Next",
  "verify": "VERIFY",
  "contact support": "Contact support",
  "back": "BACK"
};

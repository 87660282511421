export default {
  "locations": "Sedi",
  "contacts": "Contatti",
  "favourites": "Preferiti",
  "all": "Tutti",
  "noDataFound": "Nessun dato trovato",
  "thereAreNoPost": "Non ci sono post sui social, inizia a postare per vederli qui!",
  "noOrgChart": "Nessun organigramma associato a questo utente.",
  "awarded": "Premiato",
  "expires": "Scade",
  "contactDetails": "Dettagli di contatto",
  "followers": "Seguaci",
  "following": "Seguendo",
  "follow": "Seguire",
  "unfollow": "Smetti di seguire",
  "unsnooze": "Annulla il sonnellino",
  "snooze": "Snooze",
  "1Hour": "1 ora",
  "8Hours": "8 ore",
  "24Hours": "24 ore",
  "1Week": "1 settimana",
  "1Month": "1 mese",
  "1Year": "1 anno",
  "message": "Messaggio",
  "details": "Dettagli",
  "posts": "Messaggi",
  "organisation": "organizzazione",
  "profileImageHeader": "Immagine del profilo",
  "headerImageHeader": "Immagine dell'intestazione del profilo",
  "uploadFile": "Carica file",
  "zoom": "Zoom (%)",
  "rotate": "Ruota",
  "done": "Fatto",
  "ratio": "Mantieni le proporzioni",
  "on": "Abilita",
  "off": "Disabilita",
  "search": "Cerca",
  "noFavourites": "Inizia ad aggiungere i contatti preferiti!",
  "manager": "Manager",
  "location": "Posizione",
  "primaryEmail": "Email principale",
  "dob": "Data di nascita",
  "start": "Data di inizio",
  "bio": "Bio",
  "chart": "Organigramma",
  "close": "Chiudere",
  "address": "Indirizzo",
  "description": "Descrizione",
  "fName": "Nome",
  "lName": "Cognome",
  "title": "Titolo",
  "department": "Dipartimento",
  "save": "Salva",
  "error": "C'è stato un errore,",
  "errorLong": "riprova più tardi.",
  "success": "Successo!",
  "successLong": "Dettagli salvati correttamente",
  "email": "Posta elettronica",
  "badge": "Distintivi",
  "skills": "Competenze",
  "phone": "Telefono",
  "hobbies": "Hobby",
  "clearAll": "Cancella tutto",
  "quickFilters": "Filtri rapidi",
  "customSearchTextPlaceholder": "Inizia a digitare e premi invio...",
  "working hours": "Ore lavorative",
  "fun facts": "Fatti divertenti",
  "noResultsFound": "Nessun risultato trovato",
  "charsLimitMsg": "Inserisci 3 o più caratteri per effettuare la ricerca",
  "errorLoadingFollowers": "Errore durante il caricamento dei follower",
  "errorLoadingFollowings": "Errore durante il caricamento dei seguenti",
  "followsYou": "Ti segue",
  "searchContacts": "Cerca contatti",
  "searchLocations": "Cerca posizioni",
  "allContacts": "Tutti i contatti",
  "edit": "Modificare",
  "people": "Persone",
  "directory": "Direttorio",
  "noSearchResultsContactDescription": "Ops! Sembra che non riusciamo a trovare alcun contatto corrispondente alla tua ricerca.",
  "noContactResultsDescription": "Ops! Sembra che non riusciamo a trovare alcun contatto.",
  "noSearchResultsFavouriteDescription": "Ops! Sembra che non riusciamo a trovare alcun contatto preferito corrispondente alla tua ricerca.",
  "noFavouriteResultsDescription": "Ops! Nessun contatto preferito trovato. Aggiungi contatti ai tuoi preferiti per visualizzarli qui.",
  "noSearchResultsLocationDescription": "Ops! Sembra che non siamo riusciti a trovare nessuna posizione corrispondente alla tua ricerca.",
  "noLocationResultsDescription": "Ops! Sembra che non siamo riusciti a trovare nessuna posizione.",
  "clearFilters": "Cancella filtri",
  "filters": "Filtri",
  "noLocationPermission": "Ops! Sembra che tu non abbia le autorizzazioni per vedere questo. Prova un'altra posizione."
};

import React, { FunctionComponent, useEffect, useState } from 'react';
import { FeedSocialPost } from '../../../../../types/types';
import { useTranslation } from 'react-i18next';
import socialPostService from '../../../../services/socialPostsService';
import { addNotification } from '../../../../../shared/reducers/notifications/actionTypes';
import { useDispatch } from 'react-redux';
import './socialPostItem.scss';
import userService from '../../../../../web/services/usersService';
import { Button } from 'reactstrap';
import { confirmAlert } from 'react-confirm-alert';

type Props = {
    item: FeedSocialPost;
    removeItem: Function;
    canDeletePost: boolean;
    removeContentByUser?: Function;
};

enum contextMenu {
    main,
    snooze,
    report,
}

const FeedPostActions: FunctionComponent<Props> = ({ item, removeItem, canDeletePost, removeContentByUser }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const btnClasses = 'c-pointer actions text-light-black';
    const [reportText, setReportText] = useState('');
    const [menu, setMenu] = useState(contextMenu.main);
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
    });

    const handleClickOutside = (event: any) => {
        if (
            !(
                event.target.classList.contains('send-button') ||
                event.target.classList.contains('cancel-button') ||
                event.target.classList.contains('report-input') ||
                event.target.classList.contains('actions') ||
                event.target.classList.contains('fake-modal-container')
            )
        ) {
            setIsVisible(false);
        } else {
            setIsVisible(true);
        }
    };

    const deletePost = (e: any) => {
        e.stopPropagation();
        confirmAlert({
            title: `${t('feed.deletePost')}`,
            message: `${t('feed.deletePostDesc')}`,
            buttons: [
                {
                    label: t('general.yes'),
                    onClick: () => {
                        socialPostService.deletePost(
                            item.model.id,
                            () => {
                                removeItem(item.model.id);
                                dispatch(
                                    addNotification({
                                        label: `Delete Post`,
                                        text: t('general.success.general'),
                                        type: 'success',
                                    })
                                );
                            },
                            () => {
                                dispatch(
                                    addNotification({
                                        label: `Delete Post`,
                                        text: t('general.errors.errorLoadingData'),
                                        type: 'danger',
                                    })
                                );
                            }
                        );
                    },
                },
                {
                    label: t('general.no'),
                    onClick: () => {},
                },
            ],
            overlayClassName: 'zIndex1050',
        });
    };

    const unfollowHandler = () => {
        socialPostService.unfollowUser(
            item.model.author.id,
            () => {
                removeContentByUser && removeContentByUser(item.model.author.id);
                dispatch(
                    addNotification({
                        label: `Unfollow`,
                        text: t('general.success.general'),
                        type: 'success',
                    })
                );
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Unfollow`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    const snoozeHandler = (snoozeMins: number) => {
        userService.snooze(
            snoozeMins,
            item.model.author.id,
            () => {
                removeContentByUser && removeContentByUser(item.model.author.id);
                dispatch(
                    addNotification({
                        label: `Snooze`,
                        text: t('general.success.general'),
                        type: 'success',
                    })
                );
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Snooze`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    const sendReport = () => {
        if (!reportText) return;
        socialPostService.reportPost(
            item.model.id,
            reportText,
            () => {
                setMenu(contextMenu.main);
                dispatch(
                    addNotification({
                        label: `Report`,
                        text: t('general.success.general'),
                        type: 'success',
                    })
                );
            },
            () => {
                dispatch(
                    addNotification({
                        label: `Report`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
            }
        );
    };

    const getDeleteOnly = () => {
        return (
            <React.Fragment>
                {canDeletePost ? (
                    <h1 data-testid='delete-btn' className={`mb-0 ${btnClasses}`} onClick={deletePost}>
                        <span className='fa-lg fa-light fa-trash-can action-icons'></span>
                        {t('feed.deletePost')}
                    </h1>
                ) : (
                    ''
                )}
            </React.Fragment>
        );
    };

    const renderReport = () => {
        return (
            <React.Fragment>
                <p data-testid='report-label' className='report-label'>
                    {t('feed.reportReason')}
                </p>
                <textarea data-testid='report-textarea' className='w-100 report-input' rows={8} onChange={reportInputHandler}>
                    {reportText}
                </textarea>
                <div className={'report-btn-container'}>
                    <Button data-testid='report-cancel' className='float-left cancel-button' onClick={() => setMenu(contextMenu.main)}>
                        {t('general.cancel')}
                    </Button>
                    <Button disabled={reportText === ''} data-testid='submit-report-button' className='float-right send-button' onClick={sendReport}>
                        {t('feed.report')}
                    </Button>
                </div>
            </React.Fragment>
        );
    };

    const reportInputHandler = (e: any) => {
        e.stopPropagation();
        const val = e.currentTarget.value.trim();
        setReportText(val);
    };

    const renderSnooze = () => {
        return (
            <React.Fragment>
                <h3 data-testid='snooze-option' className={btnClasses} onClick={() => snoozeHandler(60 * 24 * 7)}>
                    {`1 ${t('general.week')}`}
                </h3>
                <h3 data-testid='snooze-option' className={btnClasses} onClick={() => snoozeHandler(60 * 24 * 7 * 4)}>
                    {`1 ${t('general.month')}`}
                </h3>
                <h3 data-testid='snooze-option' className={`mb-0 ${btnClasses}`} onClick={() => snoozeHandler(60 * 24 * 7 * 4 * 12)}>
                    {`1 ${t('general.year')}`}
                </h3>
            </React.Fragment>
        );
    };

    const getAllOptions = () => {
        return (
            <React.Fragment>
                <h3 data-testid='snooze-button' className={btnClasses} onClick={() => setMenu(contextMenu.snooze)}>
                    <span className='fa-lg fa-light fa-snooze action-icons snooze'></span>
                    {t('feed.snooze')}
                </h3>
                <h3 data-testid='unfollow-button' className={btnClasses} onClick={unfollowHandler}>
                    <span className='fa-lg fa-light fa-user-minus action-icons'></span>
                    {t('feed.unfollow')}
                </h3>
                <h3 data-testid='report-button' className={`mb-0 ${btnClasses}`} onClick={() => setMenu(contextMenu.report)}>
                    <span className='fa-lg fa-light fa-circle-exclamation action-icons report'></span>
                    {t('feed.reportPost')}
                </h3>
            </React.Fragment>
        );
    };

    return isVisible ? (
        <div data-testid='feed-post-actions' className={'fake-modal-container'}>
            {canDeletePost ? (
                <div className={'fake-modal-content'}>{getDeleteOnly()}</div>
            ) : menu === contextMenu.main ? (
                <div className={'fake-modal-content'}>{getAllOptions()}</div>
            ) : menu === contextMenu.report ? (
                <div data-testid='report-popup' className={'fake-modal-content'}>
                    {renderReport()}
                </div>
            ) : (
                <div className={'fake-modal-content'}>{renderSnooze()}</div>
            )}
        </div>
    ) : null;
};

export default FeedPostActions;

import '../customLayout.scss';
import React, { FunctionComponent, useCallback, useEffect, useRef, useState } from 'react';
import { faComment, faShare } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardBody, CardGroup, CardText, CardTitle } from 'reactstrap';
import FolderTile from './folderTile';
import InfiniteScroll from 'react-infinite-scroll-component';
import SpinnerLoad from '../../common/spinnerLoad/spinnerLoad';
import { ContentData, ContentType } from '../../../../types/types';
import Breadcrumbs from '../breadcrumbs';
import FilterPanel, { Sort } from '../../../components/customLayout/filterPanel';
import customLayoutService from '../../../services/customLayoutService';
import { AxiosResponse } from 'axios';
import useDebouncedCallback from '../../../hooks/useDebouncedCallback';
import { Box } from '@mui/material';

type PageTileProps = {
    contentType: ContentType;
    contentId: number;
    showFilter: boolean;
    showSearch: boolean;
    showSort: boolean;
    fixedContainer?: boolean;
};

const PageTile: FunctionComponent<PageTileProps> = ({ contentType, contentId, showFilter, showSearch, showSort, fixedContainer }) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [filterByName, setFilterByName] = useState<string>();
    const [sort, setSort] = useState<Sort>();
    const initialContentData = useRef<ContentData[]>([]);
    const [contentData, setContentData] = useState<ContentData[]>([]);
    const [hasMoreContent, setHasMoreContent] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');

    const loadContentData = (currentPage: number) => {
        if (contentData) {
            customLayoutService.getContent(contentType, contentId, currentPage, search, (response: AxiosResponse) => {
                setHasMoreContent(response.data.links.next != null);

                if (contentData.length === 0) {
                    initialContentData.current = response.data.data;
                }
                if (currentPage === 1) {
                    setContentData(response.data.data);
                } else {
                    setContentData((data) => [...data, ...response.data.data]);
                }
            });
        }
    };

    const searchContentData = useDebouncedCallback((input: string) => {
        customLayoutService.getFilteredContent(contentType, contentId, input, currentPage, (response: AxiosResponse) => {
            if (search.length >= 3) {
                setContentData(response.data.data);
                setHasMoreContent(response.data.links.next != null);
                if (response.data.links.next != null) {
                    setCurrentPage(currentPage + 1);
                }
            }
        });
    }, 300);

    useEffect(() => {
        setContentData([]);
        setHasMoreContent(false);
        setCurrentPage(1);
        loadContentData(1);
    }, [contentId]);

    useEffect(() => {
        if (currentPage > 1) {
            loadContentData(currentPage);
        }
    }, [currentPage]);

    const handleOnChangeSearch = (e: React.FormEvent<HTMLInputElement>) => {
        const value: string = (e.target as HTMLInputElement).value;

        if (search.length < 3 && value.length >= 3) {
            setCurrentPage(1);
        }

        setSearch(value);

        if (value.length >= 3) {
            setCurrentPage(1);
            searchContentData(value);
        }

        if (value.length === 0) {
            setContentData(initialContentData.current);
            setCurrentPage(2);
            setHasMoreContent(true);
        }
    };

    const getFilteredContent = (data: ContentData[], sort: Sort | undefined, filterByName: string | undefined) =>
        data
            .sort((a, b) => {
                switch (sort) {
                    case Sort.mostViewed:
                        return b.counts.views - a.counts.views;
                    case Sort.mostLiked:
                        return b.counts.likes - a.counts.likes;
                    case Sort.oldestDate:
                        return a.timestamp - b.timestamp;
                    case Sort.newestDate:
                        return b.timestamp - a.timestamp;
                    case Sort.alphabeticalAscending:
                        return a.title.toLowerCase() > b.title.toLowerCase() ? 1 : -1;
                    case Sort.alphabeticalDescending:
                        return a.title.toLowerCase() > b.title.toLowerCase() ? -1 : 1;
                    default:
                        return 0;
                }
            })
            .filter((content) => {
                return filterByName ? content.contentPage?.publisher?.name === filterByName : true;
            });

    const filteredContent = getFilteredContent(contentData, sort, filterByName);

    const handleOnClickResetAliasFilter = () => {
        setFilterByName('');
    };

    const handleOnClickFilterByName = (name: string) => {
        setFilterByName(name);
    };

    const handleOnClickFilterMostViewed = () => {
        setSort(Sort.mostViewed);
    };

    const handleOnClickFilterMostLiked = () => {
        setSort(Sort.mostLiked);
    };

    const handleOnClickFilterOldestFirst = () => {
        setSort(Sort.oldestDate);
    };

    const handleOnClickFilterNewestFirst = () => {
        setSort(Sort.newestDate);
    };

    const handleOnClickFilterAlphabetically = (isAscending: boolean) => {
        setSort(isAscending ? Sort.alphabeticalAscending : Sort.alphabeticalDescending);
    };

    const customPageScroll = (e: any) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        if (Math.round(scrollTop + clientHeight) >= scrollHeight) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    return (
        <div data-testid='page-tile' className='custom-layout'>
            {fixedContainer ? (
                <Box sx={{ height: '100%', position: 'relative', overflow: 'auto', maxWidth: '900px', margin: '0 auto' }} onScroll={customPageScroll}>
                    <Breadcrumbs contentType={contentType} contentId={contentId} />
                    <FilterPanel
                        search={search}
                        showSearch={showSearch}
                        showFilter={showFilter}
                        showSort={showSort}
                        handleOnChangeSearch={handleOnChangeSearch}
                        handleOnClickResetAliasFilter={handleOnClickResetAliasFilter}
                        handleOnClickFilterByName={handleOnClickFilterByName}
                        handleOnClickFilterMostViewed={handleOnClickFilterMostViewed}
                        handleOnClickFilterMostLiked={handleOnClickFilterMostLiked}
                        handleOnClickFilterOldestFirst={handleOnClickFilterOldestFirst}
                        handleOnClickFilterNewestFirst={handleOnClickFilterNewestFirst}
                        handleOnClickFilterAlphabetically={handleOnClickFilterAlphabetically}
                        contentFiltered={contentData}
                    />
                    <CardGroup>
                        {filteredContent.map((content) => {
                            const firstAlias = content.contentPage.publisher;
                            const imageStyle = firstAlias?.is_round ? 'rounded-circle' : '';
                            return (
                                <React.Fragment key={content.id}>
                                    {content.type === 'PAGE' ? (
                                        <Card
                                            id={`page_${content.id}`}
                                            data-testid='page-tile-card'
                                            style={{
                                                borderRadius: 10,
                                                overflow: 'hidden',
                                            }}
                                            onClick={() => {
                                                if (
                                                    !content.contentPage.allow_likes &&
                                                    !content.contentPage.allow_comments &&
                                                    !content.contentPage.allow_share &&
                                                    (content.type_name === 'URL' || content.type_name === 'SSO')
                                                ) {
                                                    window.open(content.contents);
                                                } else {
                                                    window.location.href = `/page/${content.contentPage.id}`;
                                                }
                                            }}
                                        >
                                            <img className='card-img-top preview-image' src={content.image} alt='tile-card' />
                                            <CardBody>
                                                <CardTitle className='page-tile-title' tag='h4'>
                                                    <strong>{content.contentPage.title}</strong>
                                                </CardTitle>
                                                <div>
                                                    <h5 className='card-title d-flex cursor-pointer redirect-to-component'>
                                                        {firstAlias && (
                                                            <>
                                                                <img
                                                                    className={`media-object ${imageStyle}`}
                                                                    style={{
                                                                        marginRight: '4px',
                                                                        objectFit: 'cover',
                                                                    }}
                                                                    src={firstAlias.image}
                                                                    height='43'
                                                                    width='43'
                                                                    alt='alias-group'
                                                                />
                                                                <div>
                                                                    <div className='mb0' data-cy='alias-name'>
                                                                        <strong className='font-black'>{firstAlias.name}</strong>
                                                                    </div>
                                                                    <div className='mt0'>
                                                                        <small className='font-black'>{content.contentPage.published_at}</small>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </h5>
                                                </div>
                                                <CardText>
                                                    <small
                                                        className='font-black'
                                                        dangerouslySetInnerHTML={{
                                                            __html: content.contentPage.preview_text,
                                                        }}
                                                    ></small>
                                                </CardText>
                                            </CardBody>
                                            <div className='social-bar'>
                                                <div className='social-bar-wrapper'>
                                                    {content.contentPage.allow_likes && (
                                                        <div className='font-black'>
                                                            {content.contentPage.is_liked ? (
                                                                <em className='fa fa-heart like-full' />
                                                            ) : (
                                                                <em className='fal fa-heart' />
                                                            )}
                                                            &nbsp;
                                                            {content.counts.likes}
                                                        </div>
                                                    )}
                                                    {content.contentPage.allow_comments && (
                                                        <div className='font-black'>
                                                            <FontAwesomeIcon icon={faComment} />
                                                            &nbsp;
                                                            {content.counts.comments}
                                                        </div>
                                                    )}
                                                    {content.contentPage.allow_share && (
                                                        <div className='font-black'>
                                                            <FontAwesomeIcon icon={faShare} /> Share
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Card>
                                    ) : (
                                        <FolderTile content={content} />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </CardGroup>
                </Box>
            ) : (
                <InfiniteScroll
                    dataLength={filteredContent.length}
                    next={() => {
                        setCurrentPage(currentPage + 1);
                    }}
                    hasMore={hasMoreContent}
                    scrollableTarget='page-frame-contents'
                    loader={<SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />}
                >
                    <Breadcrumbs contentType={contentType} contentId={contentId} />
                    <FilterPanel
                        search={search}
                        showSearch={showSearch}
                        showFilter={showFilter}
                        showSort={showSort}
                        handleOnChangeSearch={handleOnChangeSearch}
                        handleOnClickResetAliasFilter={handleOnClickResetAliasFilter}
                        handleOnClickFilterByName={handleOnClickFilterByName}
                        handleOnClickFilterMostViewed={handleOnClickFilterMostViewed}
                        handleOnClickFilterMostLiked={handleOnClickFilterMostLiked}
                        handleOnClickFilterOldestFirst={handleOnClickFilterOldestFirst}
                        handleOnClickFilterNewestFirst={handleOnClickFilterNewestFirst}
                        handleOnClickFilterAlphabetically={handleOnClickFilterAlphabetically}
                        contentFiltered={contentData}
                    />
                    <CardGroup>
                        {filteredContent.map((content) => {
                            const firstAlias = content.contentPage.publisher;
                            const imageStyle = firstAlias?.is_round ? 'rounded-circle' : '';
                            return (
                                <React.Fragment key={content.id}>
                                    {content.type === 'PAGE' ? (
                                        <Card
                                            id={`page_${content.id}`}
                                            data-testid='page-tile-card'
                                            style={{
                                                borderRadius: 10,
                                                overflow: 'hidden',
                                            }}
                                            onClick={() => {
                                                if (
                                                    !content.contentPage.allow_likes &&
                                                    !content.contentPage.allow_comments &&
                                                    !content.contentPage.allow_share &&
                                                    (content.type_name === 'URL' || content.type_name === 'SSO')
                                                ) {
                                                    window.open(content.contents);
                                                } else {
                                                    window.location.href = `/page/${content.contentPage.id}`;
                                                }
                                            }}
                                        >
                                            <img className='card-img-top preview-image' src={content.image} alt='tile-card' />
                                            <CardBody>
                                                <CardTitle className='page-tile-title' tag='h4'>
                                                    <strong>{content.contentPage.title}</strong>
                                                </CardTitle>
                                                <div>
                                                    <h5 className='card-title d-flex cursor-pointer redirect-to-component'>
                                                        {firstAlias && (
                                                            <>
                                                                <img
                                                                    className={`media-object ${imageStyle}`}
                                                                    style={{
                                                                        marginRight: '4px',
                                                                        objectFit: 'cover',
                                                                    }}
                                                                    src={firstAlias.image}
                                                                    height='43'
                                                                    width='43'
                                                                    alt='alias-group'
                                                                />
                                                                <div>
                                                                    <div className='mb0' data-cy='alias-name'>
                                                                        <strong className='font-black'>{firstAlias.name}</strong>
                                                                    </div>
                                                                    <div className='mt0'>
                                                                        <small className='font-black'>{content.contentPage.published_at}</small>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )}
                                                    </h5>
                                                </div>
                                                <CardText>
                                                    <small
                                                        className='font-black'
                                                        dangerouslySetInnerHTML={{
                                                            __html: content.contentPage.preview_text,
                                                        }}
                                                    ></small>
                                                </CardText>
                                            </CardBody>
                                            <div className='social-bar'>
                                                <div className='social-bar-wrapper'>
                                                    {content.contentPage.allow_likes && (
                                                        <div className='font-black'>
                                                            {content.contentPage.is_liked ? (
                                                                <em className='fa fa-heart like-full' />
                                                            ) : (
                                                                <em className='fal fa-heart' />
                                                            )}
                                                            &nbsp;
                                                            {content.counts.likes}
                                                        </div>
                                                    )}
                                                    {content.contentPage.allow_comments && (
                                                        <div className='font-black'>
                                                            <FontAwesomeIcon icon={faComment} />
                                                            &nbsp;
                                                            {content.counts.comments}
                                                        </div>
                                                    )}
                                                    {content.contentPage.allow_share && (
                                                        <div className='font-black'>
                                                            <FontAwesomeIcon icon={faShare} /> Share
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </Card>
                                    ) : (
                                        <FolderTile content={content} />
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </CardGroup>
                </InfiniteScroll>
            )}
        </div>
    );
};

export default PageTile;

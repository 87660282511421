export default {
  "referencePrompt": "Por favor insira a referência da sua empresa",
  "referenceBackupPrompt": "Não tem certeza de qual é sua referência? Tente seu endereço de e-mail",
  "referencePlaceholder": "Insira a referência da sua empresa ou e-mail",
  "please enter your user ID and password": "Por favor, digite seu ID de usuário e senha",
  "please enter user id": "Por favor insira o seu ID de utilizador",
  "enter password": "Por favor insira a sua palavra-passe",
  "forgot password": "Esqueci-me da palavra-passe",
  "errors": {
    "domain name can't be empty": "O nome de domínio não pode estar vazio",
    "user name can't be empty": "O nome do utilizador nao pode estar vazio",
    "password can't be empty": "Password não pode estar vazia",
    "incorrect user id or password": "ID de Utilizador ou Palavra-passe Incorretos",
    "domain does not exist": "Domínio'{{domain}}'não existe",
    "account locked": "Excedeu o número permitido de tentativas. A sua conta está bloqueada."
  },
  "hello": "Olá",
  "log in": "Conecte-se",
  "new user": "Novo usuário",
  "create new account": "Criar nova conta",
  "next": "Próximo",
  "verify": "VERIFICAR",
  "contact support": "Entre em contato com o suporte",
  "back": "RETROCEDER"
};

import React from 'react';
import { Box, BoxProps } from '@mui/material';
import ItemHeader from '../feedPage/itemTypes/itemHeader';
import FilesDisplay from '../common/filesDisplay/filesDisplay';

const renderHtml = require('html-react-parser');

export type RepostItemProps = {
    item: any;
    containerProps?: BoxProps;
    handleRepostClick: (e: any) => void;
};

export const RepostItem = (props: RepostItemProps) => {
    const { item, containerProps, handleRepostClick } = props;
    return (
        <Box
            sx={{
                backgroundColor: '#F7F7F7',
                padding: '0 15px',
                display: 'flex',
                flexDirection: 'column',
                gap: '5px',
                mt: '5px',
                pb: '5px',
                borderRadius: '8px',
                border: '1px solid rgba(0, 0, 0, .125)',
                ...containerProps?.sx,
            }}
            data-testid='report-item'
            onClick={handleRepostClick}
            {...containerProps}
        >
            <ItemHeader
                imgUrl={item.author.image_url}
                headText={item.author.full_name}
                jobTitle={item.author.jobtitle}
                time={item.created_at_humans || ''}
                contact_id={item.author ? item.author.contact_id : null}
            />
            <Box
                sx={{
                    pl: '5px',
                }}
            >
                {renderHtml(item.message || '')}
            </Box>
            <FilesDisplay files={item.files} className='mb-2' socialPost={true} repostItem={true} />
        </Box>
    );
};

import React, { FunctionComponent, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { AxiosResponse } from 'axios';
import { SideNavItem } from '../../../../types/types';
import { Navbar } from 'reactstrap';
import { addNotification } from '../../../../shared/reducers/notifications/actionTypes';
import { useLocation } from 'react-router-dom';
import SideNavLink from './sideNavLink';
import SpinnerLoad from '../../common/spinnerLoad/spinnerLoad';

import navigationService from '../../../services/navigationSevice';
import i18n from 'i18next';
import localeService from '../../../services/localeService';
import { useSideNavItemsQuery } from '../../../hooks/query/useSideNavItemsQuery';

type SideNavComponentProps = {
    setSideNavDetail: React.Dispatch<React.SetStateAction<{ menuItems: SideNavItem[]; unreadThreads: number }>>;
};

const SideNavComponent: FunctionComponent<SideNavComponentProps> = ({ setSideNavDetail }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const brandingColors = useSelector((state: any) => state.app.branding);
    const appState = useSelector((state: any) => state.app.app);
    const location = useLocation();
    const [unreadThreads, setUnreadThreads] = useState<number>(0);
    const { data: sideNavItems, isLoading: isSideNavItemsLoading, refetch: fetchSideNavItems } = useSideNavItemsQuery();

    useEffect(() => {
        localeService.storeLocale(
            i18n.language,
            (success: AxiosResponse) => {
                fetchSideNavItems();
            },
            (error: AxiosResponse) => {
                dispatch(
                    addNotification({
                        label: `Language`,
                        text: t('general.errors.errorReload'),
                        type: 'danger',
                    })
                );
            }
        );
        if (appState.mark_as_read_message) {
            dispatch({
                type: 'SET_APP_STATE',
                payload: {
                    ...appState,
                    mark_as_read_message: false,
                },
            });
        }
    }, [i18n.language, appState.mark_as_read_message]);

    useEffect(() => {
        if (sideNavItems) {
            setSideNavDetail({
                menuItems: sideNavItems?.items,
                unreadThreads: sideNavItems?.unreadThreads,
            });
            setUnreadThreads(sideNavItems?.unreadThreads);
        }
    }, [sideNavItems]);

    const getIsItemActive = (item: SideNavItem) => {
        // If item is Home
        if (item.path === '/') return location.pathname === item.route_url;
        if (
            (location.pathname.indexOf('/location') !== -1 && item.route_url === '/connect') ||
            (location.pathname.indexOf('/chat') !== -1 && item.route_url === '/messages')
        )
            return true;
        return location.pathname.indexOf(item.route_url) !== -1;
    };

    return (
      <React.Fragment>
        <aside
          data-testid='side-nav-component'
          id='page-frame-left'
          className={'aside position-absolute d-none d-sm-block'}
          style={{
            backgroundColor: brandingColors.web_menubar_background,
            color: brandingColors.web_menubar_foreground,
          }}
        >
          {isSideNavItemsLoading ? (
            <SpinnerLoad className='w-100 text-center position-absolute top-45'/>
          ) : (
            <Navbar className={'p-0 d-flex flex-direction-column h-100'} style={{ overflowX: "hidden", overflowY: "auto", scrollbarWidth: "thin" }}>
              <ul data-testid='side-nav-item' className={'p-0 my-0 text-center w-100 list-type-none'}>
                {sideNavItems &&
                  sideNavItems?.items &&
                  sideNavItems.items.map((item: any) => (
                    <SideNavLink
                      key={item.path}
                      item={item}
                      branding={brandingColors}
                      isActive={getIsItemActive(item)}
                      unreadThreads={
                        item.route_url.indexOf('messages') !== -1 || item.route_url.indexOf('chat') !== -1 ? unreadThreads : 0
                      }
                      displayChildrenDrawer={
                        item.children != null && (item.route === 'frontend.section' || item.route === 'frontend.category')
                      }
                    />
                  ))}
              </ul>
            </Navbar>
          )}
        </aside>
        <style>{`
                .aside .nav-link svg {
                    fill: ${brandingColors.web_menubar_foreground};
                }
            
                .aside .nav-link:hover svg,
                .aside .nav-link.active svg {
                    fill: ${brandingColors.web_menubar_active_foreground};
                }
          
                .aside .nav-link.active .unread-counter {
                  color: ${brandingColors.web_menubar_active_background} !important;
                }
            
                .aside .nav-link svg .svg-stroke-el {
                    stroke: ${brandingColors.web_menubar_foreground} !important;
                }
            
                .aside .nav-link:hover svg .svg-stroke-el,
                .aside .nav-link.active svg .svg-stroke-el {
                    stroke: ${brandingColors.web_menubar_active_foreground} !important;
                }
                .aside .navbar>div.container-fluid {
                    align-items: flex-start;
                }
                .aside .navbar, .aside .navbar .sub-nav-menu {
                    scrollbar-color: ${brandingColors.web_menubar_foreground} ${brandingColors.web_menubar_background};
                    scrollbar-width: thin;
                }
                .aside .navbar::-webkit-scrollbar, .aside .navbar .sub-nav-menu::-webkit-scrollbar {
                    background-color: transparent;
                    width: 0.4em;
                }
                .aside .navbar::-webkit-scrollbar-thumb, .aside .navbar .sub-nav-menu::-webkit-scrollbar-thumb {
                    border-radius: 8px;
                    background-color: ${brandingColors.web_menubar_foreground};
                }
            `}</style>
      </React.Fragment>
    );
};
export default SideNavComponent;

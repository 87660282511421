import React, { FunctionComponent, useState, useRef, useLayoutEffect } from 'react';
import { Card, Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FeedSocialPost, TYBrandingData } from '../../../../../types/types';
import classnames from 'classnames';
import socialPostsService from '../../../../services/socialPostsService';
import { getLikesStr, getCommentsStr } from '../../feedUtils';
import { addNotification } from '../../../../../shared/reducers/notifications/actionTypes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/pro-solid-svg-icons';
import { faHeart as faHeartReg, faShare } from '@fortawesome/pro-light-svg-icons';
import ItemHeader from '../itemHeader';
import SocialPostLikesModal from './socialPostLikesModal';
import SocialPostComments from './socialPostComments';
import Collapse from '../../../common/collapse/collapse';
import FilesDisplay from '../../../common/filesDisplay/filesDisplay';
import FeedPostActions from '../../../../../web/components/feedPage/itemTypes/socialPost/feedPostActions';
import { SharePopover } from '../../../sharePopover/sharePopover';
import { Box, Skeleton } from '@mui/material';
import { IconText } from '../../../../components/iconText/IconText';
import { Link } from '@mui/material';
import { RepostedUsersModal } from '../../../repostedUsersModal/repostedUsersModal';
import CreateSocialPost from '../../createSocialPost';

import { RepostItem } from '../../../repostItem/repostItem';

const renderHtml = require('html-react-parser');

type SocialPostItemProps = {
    item: FeedSocialPost;
    tyBranding: TYBrandingData;
    setItem: Function;
    setLiked: Function;
    removeItem: Function;
    removeContentByUser?: Function;
    addRepostToFeed?: Function;
    isLastItem?: boolean;
    socialPostPage?: boolean;
    isLoading?: boolean;
    feedData?: Array<any>;
    setFeedData?: Function;
    repostWithThoughts?: boolean;
};

enum TyCardTypes {
    public = 'TYC',
    private = 'P2P',
}

const MaxHeight = 300;

const SocialPostItem: FunctionComponent<SocialPostItemProps> = ({
    item,
    isLastItem,
    tyBranding,
    setItem,
    setLiked,
    removeItem,
    removeContentByUser,
    addRepostToFeed,
    socialPostPage,
    isLoading,
    feedData,
    setFeedData,
    repostWithThoughts,
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userInfo = useSelector((state: any) => state.app.user);

    const [likesModalOpen, setLikesModal] = useState(false);
    const [repostModalOpen, setRepostModalOpen] = useState(false);
    const [replyOpen, setReplyOpen] = useState(false);
    const [dotsModalOpen, setDotsOpen] = useState(false);
    const [isTooTall, setTooTall] = useState(false);
    const [isCollapsed, setCollapsed] = useState(false);
    const [originalHeight, setOriginalHeight] = useState(MaxHeight);
    const [openReshareWithThoughtsModal, setOpenReshareWithThoughtsModal] = useState<boolean>(false);
    const containerRef = useRef(null);

    const [shareAnchorEl, setShareAnchorEl] = useState<any>(null);
    const openSharePopover = Boolean(shareAnchorEl);
    const [isHovered, setIsHovered] = useState(false);

    useLayoutEffect(() => {
        // Check if container is too tall
        if (!isLoading) {
            const el: any = containerRef.current;
            if (el.clientHeight <= MaxHeight) return;

            setOriginalHeight(el.clientHeight);
            setTooTall(true);
        }
    }, []);

    const getMessageHtml = () => {
        if (!item.model.message) {
            return '';
        }
        return renderHtml(item.model.message);
    };

    const renderText = () => {
        if (!isTooTall) return getMessageHtml();

        const heightData = {
            minHeight: MaxHeight,
            maxHeight: originalHeight,
        };
        return (
            <React.Fragment>
                <Collapse isOpen={isCollapsed} className={'article-body'} heightData={heightData}>
                    {getMessageHtml()}
                </Collapse>
                <span className='c-pointer text-primary text-hover' onClick={() => setCollapsed(!isCollapsed)}>
                    {isCollapsed ? t('feed.hide') : t('feed.expand')}
                </span>
            </React.Fragment>
        );
    };

    const handleLike = () => {
        const liked = !item.model.is_liked;

        setLiked(item.model.id, liked);
        socialPostsService.likePost(
            item.model.id,
            liked,
            () => {},
            () => {
                dispatch(
                    addNotification({
                        label: `Post Like/Dislike`,
                        text: t('general.errors.errorLoadingData'),
                        type: 'danger',
                    })
                );
                setLiked(item.model.id, !liked);
            }
        );
    };

    const isTYcard = () => {
        return item.model.post_type === TyCardTypes.public || item.model.post_type === TyCardTypes.private;
    };

    const getIconCallback = () => {
        if (!isTYcard() && dotsModalOpen) {
            return setDotsOpen(false);
        } else {
            return isTYcard() ? undefined : setDotsOpen(true);
        }
    };

    const navigateToUser = (e: any, userId: number) => {
        e.preventDefault();
        e.stopPropagation();
        navigate(`/connect/people/${userId}`);
    };

    const handleClickShare = (e: any) => {
        e.stopPropagation();
        setShareAnchorEl(e.currentTarget);
    };

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    const renderPost = () => {
        return (
            <Box
                data-testid='social-post-card'
                onClick={(e) => {
                    e.stopPropagation();
                    if (!socialPostPage) {
                        !isTYcard() && navigate(`/social/post/${item.model.id}`);
                    }
                }}
                sx={{ cursor: !socialPostPage ? 'pointer' : 'default' }}
            >
                <ItemHeader
                    imgUrl={item.model.author.image_url}
                    headText={item.model.author.full_name}
                    jobTitle={item.model.author.jobtitle}
                    time={item.model.created_at_humans || ''}
                    iconClass={isTYcard() ? tyBranding.tycIcon : 'fal fa-ellipsis'}
                    iconClickCallback={getIconCallback}
                    contact_id={item.model.author ? item.model.author.contact_id : null}
                />
                {dotsModalOpen ? (
                    <FeedPostActions
                        item={item}
                        removeItem={removeItem}
                        removeContentByUser={removeContentByUser}
                        canDeletePost={item.model.author.id === userInfo.id}
                    ></FeedPostActions>
                ) : (
                    ''
                )}
                <div data-testid='social-post-message' className='social-post-message font-secondary-text text-black mt-3 mb-2' ref={containerRef}>
                    {renderText()}
                </div>
                <FilesDisplay files={item.model.files} socialPost={!isTYcard()}>
                    {isTYcard() ? (
                        <h3 className='mb-3 text-light-black mt-3 font-primary-text'>
                            {item.model.recipients.map((element: any) => {
                                return (
                                    <span
                                        data-testid='award-sender-name'
                                        className='sender-name c-pointer'
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            navigateToUser(e, element.contact_id);
                                        }}
                                    >
                                        @{element.full_name || ''}&nbsp;
                                    </span>
                                );
                            })}
                            {`${t('feed.received', {
                                text: tyBranding.tycText.btn.value,
                            })} ${t('awards.for')} ${item.model.image_title}`}
                        </h3>
                    ) : (
                        ''
                    )}
                </FilesDisplay>
                {item.model.manager_name && item.model.manager_name !== '' && item.model.manager_message !== '' ? (
                    <div className={'social-post-message mt-3'}>
                        <span style={{ fontSize: '1.125rem' }} className={'text-light-black sender-name font-primary-text'}>
                            <strong>{t('feed.messageToManager')}</strong>
                        </span>
                        <div className='mt-2 border-bottom' />
                        <div className={'mt-2'}>
                            <span className='sender-name text-light-black font-secondary-text'>{item.model.manager_message}</span>
                        </div>
                    </div>
                ) : (
                    ''
                )}
                {repostWithThoughts && item.model.repost_parent && (
                    <RepostItem
                        item={item.model.repost_parent}
                        handleRepostClick={(e) => {
                            e.stopPropagation();
                            navigate(`/social/post/${item.model.repost_parent.id}`);
                        }}
                    />
                )}
            </Box>
        );
    };

    if (isLoading) {
        return (
            <Card data-testid='social-post-item-card' className={`social-post-item p-3 item-container`} style={{ width: '900px' }}>
                <Box sx={{ display: 'flex', gap: '10px' }}>
                    <Skeleton variant='circular' width={65} height={65} />
                    <Box sx={{ display: 'flex', flexDirection: 'column', height: '60px', mt: '5px' }}>
                        <Skeleton variant='text' width={100} sx={{ fontSize: '14px' }} />
                        <Skeleton variant='text' width={50} sx={{ fontSize: '12px' }} />
                        <Skeleton variant='text' width={75} sx={{ fontSize: '12px' }} />
                    </Box>
                </Box>
                <Box sx={{ mt: '10px' }}>
                    <Skeleton variant='rectangular' width='100%' height={300} />
                </Box>
                <Box sx={{ mt: '10px', display: 'flex', width: '100%', gap: '20px' }}>
                    <Skeleton variant='rectangular' width='100%' height={30} />
                    <Skeleton variant='rectangular' width='100%' height={30} />
                    <Skeleton variant='rectangular' width='100%' height={30} />
                </Box>
            </Card>
        );
    }
    let repostUsers: any[] = [];
    if (item.model?.repost_users?.data?.length > 0) {
        repostUsers = item.model.repost_users.data?.map((user: any) => ({
            avatarUrl: user.avatar_url,
            fullName: user.full_name,
            contactId: user.contact_id,
            isUser: userInfo.connect_id === user.contact_id,
        }));
        let isUserIndex = repostUsers.findIndex((contact: any) => contact.isUser === true);
        if (isUserIndex !== -1) {
            let currentRepostUser = repostUsers.splice(isUserIndex, 1)[0];
            repostUsers.unshift(currentRepostUser);
        }
    }

    const renderRepostText = (users: any[]) => {
        let text = (
            <>
                {users[0].isUser ? (
                    <Link
                        href={`/connect/people/${users[0].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {t('socialPost.single_you')}
                    </Link>
                ) : (
                    <Link
                        href={`/connect/people/${users[0].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {users[0].fullName}
                    </Link>
                )}{' '}
                {users[0].isUser ? t('socialPost.you_reposted_this') : t('socialPost.singular_reposted_this')}
            </>
        );

        let htmlText = <p style={{ fontSize: '16px', marginTop: '4px', marginBottom: '8px' }}>{text}</p>;
        if (users.length > 3) {
            return (
                <p style={{ fontSize: '16px', marginTop: '4px', marginBottom: '8px' }}>
                    {users[0].isUser ? (
                        <Link
                            href={`/connect/people/${users[0].contactId}`}
                            underline='hover'
                            style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                        >
                            {t('socialPost.you')}
                        </Link>
                    ) : (
                        <Link
                            href={`/connect/people/${users[0].contactId}`}
                            underline='hover'
                            style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                        >
                            {users[0].fullName}
                        </Link>
                    )}
                    ,{' '}
                    <Link
                        href={`/connect/people/${users[1].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {users[1].fullName}
                    </Link>
                    ,{' '}
                    <Link
                        href={`/connect/people/${users[2].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {users[2].fullName}
                    </Link>{' '}
                    {t('socialPost.and')}
                    <button
                        onClick={() => setRepostModalOpen(true)}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{
                            fontSize: '16px',
                            fontWeight: '600',
                            textDecoration: isHovered ? 'underline' : 'none',
                            color: 'black',
                            cursor: 'pointer',
                            background: 'none',
                            border: 'none',
                        }}
                    >
                        {t('socialPost.more', { dynamicValue: item.model.repost_count - 3 })}
                    </button>
                    {users[0].isUser ? t('socialPost.we_reposted_this') : t('socialPost.they_reposted_this')}
                </p>
            );
        } else if (users.length === 3) {
            return (
                <p style={{ fontSize: '16px', marginTop: '4px', marginBottom: '8px' }}>
                    {users[0].isUser ? (
                        <Link
                            href={`/connect/people/${users[0].contactId}`}
                            underline='hover'
                            style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                        >
                            {t('socialPost.you')}
                        </Link>
                    ) : (
                        <Link
                            href={`/connect/people/${users[0].contactId}`}
                            underline='hover'
                            style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                        >
                            {users[0].fullName}
                        </Link>
                    )}
                    ,{' '}
                    <Link
                        href={`/connect/people/${users[1].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {users[1].fullName}
                    </Link>{' '}
                    {t('socialPost.and')}{' '}
                    <Link
                        href={`/connect/people/${users[2].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {users[2].fullName}
                    </Link>{' '}
                    {users[0].isUser ? t('socialPost.we_reposted_this') : t('socialPost.they_reposted_this')}
                </p>
            );
        } else if (users.length === 2) {
            return (
                <p style={{ fontSize: '16px', marginTop: '4px', marginBottom: '8px' }}>
                    {users[0].isUser ? (
                        <Link
                            href={`/connect/people/${users[0].contactId}`}
                            underline='hover'
                            style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                        >
                            {t('socialPost.you')}
                        </Link>
                    ) : (
                        <Link
                            href={`/connect/people/${users[0].contactId}`}
                            underline='hover'
                            style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                        >
                            {users[0].fullName}
                        </Link>
                    )}{' '}
                    {t('socialPost.and')}{' '}
                    <Link
                        href={`/connect/people/${users[1].contactId}`}
                        underline='hover'
                        style={{ fontSize: '16px', fontWeight: '600', color: 'black' }}
                    >
                        {users[1].fullName}
                    </Link>{' '}
                    {users[0].isUser ? t('socialPost.we_reposted_this') : t('socialPost.they_reposted_this')}
                </p>
            );
        }
        return htmlText;
    };

    const handleReshareWithThoughsClick = () => {
        setOpenReshareWithThoughtsModal(true);
        setShareAnchorEl(null);
    };

    return (
        <React.Fragment>
            {openReshareWithThoughtsModal && (
                <CreateSocialPost
                    repostModal={openReshareWithThoughtsModal}
                    setRepostModal={setOpenReshareWithThoughtsModal}
                    feedDataMethod={addRepostToFeed}
                    userInfo={userInfo}
                    repost={true}
                    repostItem={item}
                />
            )}
            <SocialPostLikesModal
                postId={item?.model?.id}
                isOpen={likesModalOpen}
                toggle={() => {
                    setLikesModal(!likesModalOpen);
                }}
            />
            <RepostedUsersModal
                postId={item?.model?.id}
                isOpen={repostModalOpen}
                toggle={() => {
                    setRepostModalOpen(!repostModalOpen);
                }}
            />
            {item.model?.repost_users?.data?.length > 0 ? (
                <Card
                    data-testid='social-post-item-card'
                    className={`social-post-item p-3 pb-0 pt-0 item-container ${isLastItem ? '' : 'mb-3'}`}
                    style={{ maxWidth: socialPostPage ? '900px' : '100%', width: socialPostPage ? '650px' : '100%' }}
                >
                    <Box className='border-bottom d-flex flex-row align-items-center ' style={{ paddingTop: '8px' }}>
                        <IconText
                            icon={repostUsers.slice(0, 3).map((user: any, index: number) => (
                                <Link href={`/connect/people/${user.contactId}`}>
                                    <img
                                        src={user.avatarUrl}
                                        style={{
                                            borderRadius: '50%',
                                            boxShadow: 'inset 0px 0px 0px 20px #fff',
                                            height: '30px',
                                            marginLeft: index === 0 ? '0' : '-20px',
                                            position: 'relative',
                                            width: '30px',
                                        }}
                                    />
                                </Link>
                            ))}
                            text={''}
                            children={renderRepostText(repostUsers)}
                        />
                    </Box>
                    {isTYcard() && item.additional_model && item.additional_model.social_post && item.additional_model.social_post ? (
                        <a
                            data-testid='award-link'
                            href={`/awards/${item.additional_model.social_post.thank_you_card.id}`}
                            className='d-block text-decoration-none'
                        >
                            {renderPost()}
                        </a>
                    ) : (
                        renderPost()
                    )}
                    <Row className='pb-2'>
                        <Col lg={6} xs={6} className='d-flex align-items-center'>
                            {item.model.likes && item.model.likes > 0 ? (
                                <span
                                    data-testid='total-likes'
                                    className='me-0 c-pointer d-flex align-items-center'
                                    onClick={() => setLikesModal(true)}
                                >
                                    <i className='fa-solid fa-circle-heart like-btn me-2'></i>
                                    <span className='cursor-pointer mr0 d-inline font-secondary-text'>
                                        {`${item.model.likes} ${getLikesStr(item.model.likes || 0)}`}
                                    </span>
                                </span>
                            ) : (
                                ''
                            )}
                        </Col>
                        <Col lg={6} xs={6} className='d-flex justify-content-end'>
                            {item.model.comments && item.model.comments > 0 ? (
                                <span className='d-flex align-items-center font-secondary-text'>
                                    <i className='fa-solid fa-message-lines comment-btn-primary comment-btn me-2'></i>
                                    {`${item.model.comments} ${getCommentsStr(item.model.comments || 0)}`}
                                </span>
                            ) : (
                                ''
                            )}
                        </Col>
                    </Row>

                    <Row className='border-top py-2'>
                        <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                            <div data-testid='like-btn' onClick={handleLike}>
                                <FontAwesomeIcon
                                    icon={item.model.is_liked ? faHeart : faHeartReg}
                                    style={{ color: item.model.is_liked ? '#d9534f' : 'inherit' }}
                                    className='c-pointer media-option'
                                    data-testid='like-btn-icon'
                                />
                                <span className='ms-2 me-0 c-pointer text-light-black media-option' style={{ fontWeight: 'bold' }}>
                                    {t('general.like')}
                                </span>
                            </div>
                        </Col>

                        <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                            <div data-testid='comment-btn' onClick={() => setReplyOpen(!replyOpen)}>
                                <i className='fa-regular fa-message-lines comment-btn c-pointer media-option'></i>
                                <span className={classnames('ms-2 me-0 text-light-black c-pointer media-option')} style={{ fontWeight: 'bold' }}>
                                    {t('general.comment')}
                                </span>
                            </div>
                        </Col>

                        <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                            {userInfo.spEnabled && (
                                <>
                                    <span
                                        data-testid='share-btn'
                                        className='ms-1 me-0 c-pointer feed-share-btn d-flex justify-content-center align-items-center'
                                        onClick={handleClickShare}
                                    >
                                        <FontAwesomeIcon icon={faShare} className='c-pointer me-1 page-footer-option' />
                                        <span className='page-footer-option ms-2 text-light-black' style={{ fontWeight: 'bold' }}>
                                            {t('general.share')}
                                        </span>
                                    </span>
                                    <SharePopover
                                        popoverProps={{
                                            open: openSharePopover,
                                            anchorEl: shareAnchorEl,
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                            onClose: () => {
                                                setShareAnchorEl(null);
                                            },
                                        }}
                                        postId={item.model.id}
                                        authorName={item.model.author.full_name}
                                        repostHandler={addRepostToFeed}
                                        handleThoughtsClick={handleReshareWithThoughsClick}
                                        setShareAnchorEl={setShareAnchorEl}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>

                    <SocialPostComments item={item} setItem={setItem} addReplyOpen={replyOpen} setReplyOpen={setReplyOpen} />
                </Card>
            ) : (
                <Card
                    data-testid='social-post-item-card'
                    className={`social-post-item p-3 pb-0 pt-0 item-container ${isLastItem ? '' : 'mb-3'}`}
                    style={{ maxWidth: socialPostPage ? '900px' : '100%', width: socialPostPage ? '650px' : '100%' }}
                >
                    {isTYcard() && item.additional_model && item.additional_model.social_post && item.additional_model.social_post ? (
                        <a
                            data-testid='award-link'
                            href={`/awards/${item.additional_model.social_post.thank_you_card.id}`}
                            className='d-block text-decoration-none'
                        >
                            {renderPost()}
                        </a>
                    ) : (
                        renderPost()
                    )}
                    <Row className='py-2'>
                        <Col lg={6} xs={6} className='d-flex align-items-center'>
                            {item.model.likes && item.model.likes > 0 ? (
                                <span
                                    data-testid='total-likes'
                                    className='me-0 c-pointer d-flex align-items-center'
                                    onClick={() => setLikesModal(true)}
                                >
                                    <i className='fa-solid fa-circle-heart like-btn me-2'></i>
                                    <span className='cursor-pointer mr0 d-inline font-secondary-text'>
                                        {`${item.model.likes} ${getLikesStr(item.model.likes || 0)}`}
                                    </span>
                                </span>
                            ) : (
                                ''
                            )}
                        </Col>
                        <Col lg={6} xs={6} className='d-flex justify-content-end'>
                            {item.model.comments && item.model.comments > 0 ? (
                                <span className='d-flex align-items-center font-secondary-text'>
                                    <i className='fa-solid fa-message-lines comment-btn-primary comment-btn me-2'></i>
                                    {`${item.model.comments} ${getCommentsStr(item.model.comments || 0)}`}
                                </span>
                            ) : (
                                ''
                            )}
                        </Col>
                    </Row>

                    <Row className='border-top py-2'>
                        <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                            <div data-testid='like-btn' onClick={handleLike}>
                                <FontAwesomeIcon
                                    icon={item.model.is_liked ? faHeart : faHeartReg}
                                    style={{ color: item.model.is_liked ? '#d9534f' : 'inherit' }}
                                    className='c-pointer media-option'
                                    data-testid='like-btn-icon'
                                />
                                <span className='ms-2 me-0 c-pointer text-light-black media-option' style={{ fontWeight: 'bold' }}>
                                    {t('general.like')}
                                </span>
                            </div>
                        </Col>

                        <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                            <div data-testid='comment-btn' onClick={() => setReplyOpen(!replyOpen)}>
                                <i className='fa-regular fa-message-lines comment-btn c-pointer media-option'></i>
                                <span className={classnames('ms-2 me-0 text-light-black c-pointer media-option')} style={{ fontWeight: 'bold' }}>
                                    {t('general.comment')}
                                </span>
                            </div>
                        </Col>

                        <Col lg={4} xs={4} className='d-flex justify-content-center align-items-center'>
                            {userInfo.spEnabled && (
                                <>
                                    <span
                                        data-testid='share-btn'
                                        className='ms-1 me-0 c-pointer feed-share-btn d-flex justify-content-center align-items-center'
                                        onClick={handleClickShare}
                                    >
                                        <FontAwesomeIcon icon={faShare} className='c-pointer me-1 page-footer-option' />
                                        <span className='page-footer-option ms-2 text-light-black' style={{ fontWeight: 'bold' }}>
                                            {t('general.share')}
                                        </span>
                                    </span>
                                    <SharePopover
                                        popoverProps={{
                                            open: openSharePopover,
                                            anchorEl: shareAnchorEl,
                                            anchorOrigin: { vertical: 'bottom', horizontal: 'left' },
                                            onClose: () => {
                                                setShareAnchorEl(null);
                                            },
                                        }}
                                        postId={item.model.id}
                                        authorName={item.model.author.full_name}
                                        repostHandler={addRepostToFeed}
                                        feedData={feedData}
                                        setFeedData={setFeedData}
                                        handleThoughtsClick={handleReshareWithThoughsClick}
                                        setShareAnchorEl={setShareAnchorEl}
                                    />
                                </>
                            )}
                        </Col>
                    </Row>

                    <SocialPostComments item={item} setItem={setItem} addReplyOpen={replyOpen} setReplyOpen={setReplyOpen} />
                </Card>
            )}
        </React.Fragment>
    );
};

export default SocialPostItem;

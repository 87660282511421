import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardBody, CardGroup, CardText, CardTitle } from 'reactstrap';
import { Grid } from '@mui/material';
import { Layout } from '../../../../types/types';
import SpinnerLoad from '../../common/spinnerLoad/spinnerLoad';
import '../homePage.scss';

type StandardLayoutProps = {
    layoutData: Layout[];
    isLoading: boolean;
    cardStyle?: any;
    imageStyle?: any;
};

const StandardLayout: FunctionComponent<StandardLayoutProps> = ({ layoutData, isLoading, cardStyle, imageStyle }) => {
    const navigate = useNavigate();
    const cardTypeStyles = (layout: Layout) => {
        if (layout.background_image_web) {
            return {
                position: 'relative',
                borderRadius: '10px',
            };
        } else {
            return {
                backgroundColor: layout.background_colour_web,
                backgroundImage: `url(${layout.background_image_web})`,
                color: layout.label_colour_web,
                marginBottom: '0px',
                borderRadius: '10px',
            };
        }
    };

    const backgroundStructure = (layout: Layout) => {
        return (
            <div
                style={{
                    backgroundImage: `url(${layout.background_image_web})`,
                    backgroundColor: layout.background_colour_web,
                    color: layout.label_colour_web,
                }}
                className={'background-layer'}
            />
        );
    };

    const imageStructure = (layout: Layout) => {
        return (
            <div className={'image-layer-container backdrop-blur'}>
                <img src={layout.background_image_web} className={'image-layer'} alt={'image-layer'} />
            </div>
        );
    };

    const homePageCardsRoutes = (e: any, layout: Layout) => {
        if (layout.web_page_layout === 'custom') {
            navigate(`/custom/section/${layout.id}`);
        }
        if (layout.web_page_layout === 'tile') {
            navigate(`/tile/section/${layout.id}`);
        }
        if (layout.web_page_layout === 'list') {
            window.location.href = `/section/${layout.id}`;
        }
    };

    return (
        <>
            {isLoading ? (
                <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
            ) : (
                <Grid container data-testid='web-layout' spacing={1}>
                    {layoutData
                        .sort((a, b) => a.order - b.order)
                        .map((layout) => {
                            return (
                                <Grid item xs={12} sm={6} md={4}>
                                    <Card
                                        id={`content_${layout.id}`}
                                        data-testid='toggle-card'
                                        onKeyPress={(e) => {
                                            if (e.key == 'Enter') {
                                                homePageCardsRoutes(e, layout);
                                            }
                                        }}
                                        onClick={(e) => homePageCardsRoutes(e, layout)}
                                        style={{
                                            ...cardTypeStyles(layout),
                                            ...cardStyle,
                                        }}
                                        key={layout.id}
                                        tabIndex={0}
                                    >
                                        {layout.background_image_web ? backgroundStructure(layout) : null}
                                        {layout.background_image_web ? imageStructure(layout) : null}
                                        <div
                                            style={{
                                                ...imageStyle,
                                                ...(layout.background_image_web ? { position: 'absolute' } : ''),
                                            }}
                                        >
                                            {layout.icon_image_web ? (
                                                <img src={layout.icon_image_web} alt='Section Icon' className='card-img' />
                                            ) : null}
                                            <CardBody className='section-card-body' style={{ color: layout.label_colour_web }}>
                                                <CardTitle tag='h2'>{layout.title}</CardTitle>
                                                <CardText>{layout.subtitle}</CardText>
                                            </CardBody>
                                        </div>
                                    </Card>
                                </Grid>
                            );
                        })}
                </Grid>
            )}
        </>
    );
};

export default StandardLayout;

import React from 'react';
import { Grid } from '@mui/material';
import SocialPostItem from '../../components/feedPage/itemTypes/socialPost/SocialPostItem';
import { useSelector } from 'react-redux';
import { useSocialPostQuery } from '../../hooks/query/useSocailPostQuery';
import { useParams, useNavigate } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { FeedSocialPost } from '../../../types/types';
import { cloneDeep } from 'lodash';
import { NoResultsWidget } from '../../components/noResultWidget/noResultWidget';
import { useTranslation } from 'react-i18next';

export const SocialPost = () => {
    const tenantInfo = useSelector((state: any) => state.app.tenant);
    const { id } = useParams<{ id: string }>();
    const { data: socialPost, isLoading: isFetchingSocialPost, isError: featchSocialPostError } = useSocialPostQuery(parseInt(id as string));
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const setLiked = (postId: number, isLiked: boolean) => {
        queryClient.setQueryData(['socialPost', postId], (oldData: any) => {
            return {
                ...oldData,
                model: {
                    ...oldData.model,
                    is_liked: isLiked,
                    likes: isLiked ? oldData.model.likes + 1 : oldData.model.likes - 1,
                },
            };
        });
    };

    const setItem = (post: FeedSocialPost) => {
        queryClient.setQueryData(['socialPost', parseInt(id as string)], () => {
            const newPost = cloneDeep(post);
            return newPost;
        });
    };

    const removeItem = () => {
        navigate('/feed');
    };

    if (featchSocialPostError) {
        return (
            <NoResultsWidget
                description={t('socialPost.postUnavailable') as string}
                title={t('general.errors.errorLoadingPage')}
                containerSx={{
                    height: '75vh',
                }}
                iconSx={{
                    margin: '10px 0',
                }}
            />
        );
    }

    return (
        <Grid data-testid='social-post-page' display={'flex'} justifyContent={'center'}>
            <SocialPostItem
                item={socialPost}
                isLastItem={false}
                tyBranding={tenantInfo.tyBranding}
                setItem={setItem}
                setLiked={setLiked}
                removeItem={removeItem}
                socialPostPage={true}
                isLoading={isFetchingSocialPost}
                repostWithThoughts={true}
            />
        </Grid>
    );
};

import React from 'react';
import { Grid, GridProps } from '@mui/material';
import { ContactCard } from '../../components/contactCard/contactCard';
import { UseMutateFunction } from 'react-query';
import { AxiosResponse } from 'axios';
import { useParams } from 'react-router-dom';

export type PeopleListProps = {
    contacts: any[] | null;
    loading: boolean;
    containerProps?: GridProps;
    updateFavourite?: UseMutateFunction<
        AxiosResponse<any, any>,
        unknown,
        {
            id: string;
            data: any;
        },
        unknown
    >;
    handleContactCardClick?: (id: string) => void;
};

export const PeopleList = (props: PeopleListProps) => {
    const { contacts, loading, containerProps, updateFavourite, handleContactCardClick } = props;
    const { id: personID } = useParams();

    if (loading) {
        return (
            <>
                {Array(9)
                    .fill(0)
                    .map((_, index) => {
                        return (
                            <React.Fragment key={index}>
                                <Grid item {...containerProps}>
                                    <ContactCard
                                        containerSx={{
                                            width: '100%',
                                        }}
                                        key={index}
                                        loading={loading}
                                    />
                                </Grid>
                            </React.Fragment>
                        );
                    })}
            </>
        );
    }

    return (
        <>
            {contacts?.map((contact: any, index: number) => {
                return (
                    <React.Fragment key={index}>
                        <Grid item {...containerProps}>
                            <ContactCard
                                containerSx={{
                                    width: '100%',
                                    backgroundColor: personID == contact.id ? '#E3E3E3' : 'white',
                                }}
                                img_url={contact.user.image_url}
                                full_name={contact.user.full_name}
                                job_title={contact.user.job_title}
                                email={contact.user.email}
                                phone={contact.user.phone}
                                location={contact.user.location}
                                awards={contact.user.awards}
                                is_favourite={contact.is_favorite}
                                manager_id={contact.user.manager_id}
                                loading={loading}
                                id={contact.id}
                                user_id={contact.user.id}
                                handleClick={handleContactCardClick}
                                updateFavourite={updateFavourite}
                            />
                        </Grid>
                    </React.Fragment>
                );
            })}
        </>
    );
};

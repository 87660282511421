import React, { useEffect, useRef, useState } from 'react';
import { Paper, Box, Avatar, Typography, SxProps, Grid, Popover } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVolumeXmark, faCircle, faBellSlash, faBell, faBoxArchive } from '@fortawesome/pro-solid-svg-icons';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useTranslation } from 'react-i18next';
import { formatShortDateTime } from '../../../web/utils/dateTimeFormat';
import './thread.scss';
import { faCircleEllipsis } from '@fortawesome/pro-light-svg-icons';
import { useArchiveThread, useUnarchiveThread } from '../../hooks/useArchiveThreadHook';
import MuteThread from '../muteThread/muteThread';
import { useDispatch } from 'react-redux';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import { useMarkAsMute } from '../../hooks/useMarkAsMute';
import moment from 'moment';

export type ThreadProps = {
    image_url: string;
    title: string;
    updatedAtHuman?: string;
    deleted?: boolean;
    updatedAt: string;
    post: string;
    id: number;
    sx?: SxProps;
    description: string;
    threadType: string;
    mutedUntil?: string | undefined;
    unread_count: number;
    isArchived?: boolean;
    mutedByAdmin?: boolean;
    handleClick: (id: number, unread_message: boolean) => void;
    refreshThreadDetail: (isLeaveGroup?: boolean) => void;
    handleArchiveThread: (threadId: string | undefined) => void;
};

export const Thread = React.memo((props: ThreadProps) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const location = useLocation();
    const { id: threadID } = useParams<{ id: string }>();
    const {
        image_url,
        title,
        updatedAt,
        updatedAtHuman,
        post,
        sx,
        id,
        description,
        deleted,
        threadType,
        mutedUntil,
        isArchived,
        unread_count,
        mutedByAdmin,
        handleArchiveThread,
        refreshThreadDetail,
        handleClick,
    } = props;
    const muted = threadType === 'public' || (mutedUntil && new Date(mutedUntil) > new Date()) || mutedByAdmin;
    let unread_message = unread_count > 0;
    const [showActionButton, setShowActionButton] = useState<boolean>(false);
    const [muteOptionValue, setMuteOptionValue] = useState<string>('60'); // Default value 60 minutes
    const [anchorMenuEl, setAnchorMenuEl] = React.useState<HTMLButtonElement | null>(null);
    const [anchorMuteEl, setAnchorMuteEl] = React.useState<HTMLButtonElement | null>(null);
    const [openMutePopover, setOpenMutePopover] = React.useState<boolean>(false);
    const [threadHumanTime, setThreadHumanTime] = useState<string>(moment.utc(updatedAt).local().fromNow());
    const actionMenuRef = useRef(null);
    const { mutate: muteMutation } = useMarkAsMute(refreshThreadDetail);
    const openActionPopover = Boolean(anchorMenuEl);

    const updateThreadHumanTime = () => {
        if (updatedAtHuman) {
            setThreadHumanTime(moment.utc(updatedAt).local().fromNow());
        }
    };

    useEffect(() => {
        updateThreadHumanTime(); // Initial update
        let interval = setInterval(updateThreadHumanTime, 10000);
        return () => clearInterval(interval);
    }, [updatedAtHuman, updatedAt]);

    useEffect(() => {
        if (!showActionButton) {
            setAnchorMenuEl(null);
            setAnchorMuteEl(null);
            setOpenMutePopover(false);
        }
    }, [showActionButton]);

    const handleActionPopoverClose = () => {
        setAnchorMenuEl(null);
        setShowActionButton(false);
    };

    const handleMutePopoverClose = () => {
        setOpenMutePopover(false);
        setShowActionButton(false);
    };

    const { mutate: archiveMutate } = useArchiveThread(
        () => {
            handleActionPopoverClose();
            refreshThreadDetail();
            if (parseInt(threadID as string) == id) handleArchiveThread(threadID);
        },
        () => {
            dispatch(
                addNotification({
                    label: t('chat.archive'),
                    text: t('chat.archiveErrorMsg'),
                    type: 'danger',
                })
            );
        }
    );

    const { mutate: unArchiveMutate } = useUnarchiveThread(
        () => {
            handleActionPopoverClose();
            refreshThreadDetail();
            if (parseInt(threadID as string) == id) handleArchiveThread(threadID);
        },
        () => {
            dispatch(
                addNotification({
                    label: t('chat.unarchive'),
                    text: t('chat.unArchiveErrorMsg'),
                    type: 'danger',
                })
            );
        }
    );

    return (
        <Paper
            sx={sx}
            elevation={0}
            className='thread'
            data-testid='thread'
            onMouseOver={() => {
                if (threadType != 'public' && !mutedByAdmin) setShowActionButton(true);
            }}
            onMouseOut={() => {
                if (showActionButton) setShowActionButton(false);
            }}
            onClick={() => {
                handleClick(id, unread_count > 0);
                navigate(`/chat/${id}`, {
                    state: {
                        title,
                        description,
                        icon_url: image_url,
                        mutedUntil: mutedUntil ? mutedUntil : '',
                    },
                });
            }}
        >
            <Grid container sx={{ display: 'flex', alignItems: 'center' }}>
                <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'center' }} className={'thumb48'}>
                    <Avatar src={image_url} alt='avatar' data-testid='thread-avatar' className={'thumb48'} />
                </Grid>
                <Grid item xs={10} sx={{ height: '40px' }}>
                    <Box sx={{ display: 'flex', pb: '4px', justifyContent: 'space-between', gap: '5px' }}>
                        <Typography
                            sx={{ fontSize: '16px', fontWeight: unread_message ? 700 : 600 }}
                            className='thread-title-preview'
                            data-testid='thread-title'
                        >
                            {title}
                        </Typography>
                        <div className='d-flex align-items-center'>
                            <Typography
                                sx={{ fontSize: '12px', fontWeight: 300, px: '5px', whiteSpace: 'nowrap', lineHeight: 1.5, marginRight: '2px' }}
                                data-testid='thread-updatedAt'
                            >
                                {threadHumanTime}
                            </Typography>
                            {showActionButton && (
                                <>
                                    <button
                                        ref={actionMenuRef}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            event.preventDefault();
                                            setAnchorMenuEl(event.currentTarget);
                                        }}
                                        className='menu-btn d-flex align-items-center justify-content-center'
                                    >
                                        <FontAwesomeIcon className='menu-icon' icon={faCircleEllipsis} />
                                    </button>
                                    <Popover
                                        id={openActionPopover ? 'simple-popover' : undefined}
                                        open={openActionPopover}
                                        className='thread-options'
                                        anchorEl={anchorMenuEl}
                                        onClick={(e) => e.stopPropagation()}
                                        onClose={(e: any) => {
                                            e.stopPropagation();
                                            handleActionPopoverClose();
                                        }}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                    >
                                        {threadType != 'public' && !mutedByAdmin && (
                                            <>
                                                <button
                                                    className='btn mute-btn d-flex align-items-center'
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        if (muted) {
                                                            // Unmute API call
                                                            muteMutation({
                                                                threadID: id.toString(),
                                                                formData: {
                                                                    muted_until: null,
                                                                },
                                                            });
                                                            setAnchorMenuEl(null);
                                                            setShowActionButton(false);
                                                            if (threadID == id.toString()) {
                                                                navigate('.', {
                                                                    replace: true,
                                                                    state: {
                                                                        ...location?.state,
                                                                        mutedUntil: '',
                                                                    },
                                                                });
                                                            }
                                                        } else {
                                                            // Open Mute Popover
                                                            setAnchorMenuEl(null);
                                                            setOpenMutePopover(true);
                                                            setAnchorMuteEl(actionMenuRef.current);
                                                        }
                                                    }}
                                                >
                                                    <FontAwesomeIcon
                                                        className='c-pointer me-2 mute-option-icon'
                                                        icon={muted ? faBellSlash : faBell}
                                                    />
                                                    <p className='m-0 mute-option-text'>{muted ? t('chat.unmute') : t('chat.mute')}</p>
                                                </button>
                                            </>
                                        )}

                                        {threadType === 'private' && (
                                            <button
                                                className='btn archive-btn d-flex align-items-center'
                                                onClick={(event) => {
                                                    event.stopPropagation();
                                                    if (isArchived) {
                                                        // Unarchive API call
                                                        unArchiveMutate(parseInt(id.toString()));
                                                    } else {
                                                        // Archive API call
                                                        archiveMutate(parseInt(id.toString()));
                                                    }
                                                }}
                                            >
                                                <FontAwesomeIcon className='c-pointer me-2 archive-option-icon' icon={faBoxArchive} />
                                                <p className='m-0 archive-option-text'>{isArchived ? t('chat.unarchive') : t('chat.archive')}</p>
                                            </button>
                                        )}
                                    </Popover>
                                    {threadType != 'public' && !mutedByAdmin && openMutePopover && (
                                        <MuteThread
                                            isOpen={openMutePopover}
                                            anchorEl={anchorMuteEl}
                                            muted={muted as boolean}
                                            mutedUntil={mutedUntil}
                                            threadID={id.toString()}
                                            muteOptionValue={muteOptionValue}
                                            isFromThreadList={true}
                                            setAnchorEl={setAnchorMenuEl}
                                            setMuteOptionValue={setMuteOptionValue}
                                            handleMutePopoverClose={handleMutePopoverClose}
                                            muteMutation={muteMutation}
                                        />
                                    )}
                                </>
                            )}
                        </div>
                    </Box>
                    <Box className='thread-preview-section'>
                        <Typography
                            className='thread-title-preview'
                            data-testid='thread-post'
                            sx={{ fontWeight: unread_message ? 700 : 300, lineHeight: '1.3' }}
                        >
                            {deleted ? `${post.split(':')[0]}: ${t('chat.deletedMessageThread')}` : post}
                        </Typography>
                        {unread_message && !muted && (
                            <Typography className='thread-icon-section' data-testid='thread-unread-notification'>
                                <FontAwesomeIcon className='thread-icon' icon={faCircle} />
                            </Typography>
                        )}
                        {muted && (
                            <Typography
                                className='thread-icon-section'
                                data-testid='thread-mute-icon'
                                sx={{
                                    display: unread_message ? 'flex' : 'block',
                                    gap: unread_message ? '5px' : '0',
                                    alignItems: unread_message ? 'center' : 'normal',
                                }}
                            >
                                {unread_message && (
                                    <Typography className='thread-icon-section' data-testid='thread-unread-notification'>
                                        <FontAwesomeIcon className='thread-icon' icon={faCircle} />
                                    </Typography>
                                )}
                                <ReactTooltip className='tool-tip' anchorId={`mute_${id}`} />
                                <FontAwesomeIcon
                                    onClick={(e) => e.stopPropagation()}
                                    className='thread-icon'
                                    icon={faVolumeXmark}
                                    id={`mute_${id}`}
                                    data-tooltip-content={
                                        threadType === 'public' || mutedByAdmin
                                            ? threadType === 'public'
                                                ? t('chat.muteMsg')
                                                : t('chat.muteByAdminMsg')
                                            : `${t('chat.prefixMuteMsg')}: ${formatShortDateTime(mutedUntil as string, true)}`
                                    }
                                />
                            </Typography>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
});

import React, { FunctionComponent, useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Button, Card, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import socialPostsService from '../../services/socialPostsService';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import SpinnerLoad from '../common/spinnerLoad/spinnerLoad';
import { Box } from '@mui/material';

export type RepostedUsersModalProps = {
    postId: number;
    isOpen: boolean;
    toggle: any;
};

export const RepostedUsersModal: FunctionComponent<RepostedUsersModalProps> = ({ postId, isOpen, toggle }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [repostedUsersData, setRepostedUsersData] = useState<Array<any>>([]);
    const [page, setPage] = useState<number>(1);
    const [dataLoading, setDataLoading] = useState(true);
    const [hasMoreData, setHasMoreData] = useState(false);

    useEffect(() => {
        if (isOpen) {
            loadRepostedUsers();
        }
    }, [isOpen]);

    const loadRepostedUsers = () => {
        setDataLoading(true);
        socialPostsService.loadRepostUsers(
            postId,
            page,
            (resp: AxiosResponse) => {
                appendUsers(resp.data.data);
                let moreData: boolean = resp.data.links.next;
                if (moreData) {
                    setPage((prev) => prev + 1);
                    setHasMoreData(true);
                } else {
                    setHasMoreData(false);
                }
                setDataLoading(false);
            },
            () => {
                dispatch(addNotification({ label: `Reposted Users`, text: t('general.errors.errorLoadingPage'), type: 'danger' }));
                setDataLoading(false);
            }
        );
    };

    const handleScroll = (e: any) => {
        const target = e.target;
        if (target.scrollHeight - target.scrollTop === target.clientHeight) {
            if (hasMoreData) {
                loadRepostedUsers();
            }
        }
    };

    const appendUsers = (nextUsers: Array<string>) => {
        setRepostedUsersData([...repostedUsersData, ...nextUsers]);
    };

    const navigateUser = (e: any, userId: string) => {
        e.preventDefault();
        navigate(`/connect/people/${userId}`);
    };

    const renderRepostedUsers = (repostedUsers: any, index: number) => {
        return (
            <Card
                data-testid='reposted-users-card'
                className='social-likes-modal-item c-pointer mb-2 border-0'
                onClick={(e) => navigateUser(e, repostedUsers.contact_id)}
                key={index}
            >
                <div className='p-2 d-flex align-items-center'>
                    <img
                        data-testid='author-image'
                        className='rounded-circle thumb64'
                        src={repostedUsers.avatar_url}
                        alt={`${repostedUsers.full_name}'s Image`}
                    />
                    <div className='ms-3'>
                        <h3 data-testid='author-full-name' className='m-0 text-secondary'>
                            {repostedUsers.full_name}
                        </h3>
                    </div>
                </div>
            </Card>
        );
    };

    return (
        <Modal data-testid='social-post-reposted-users-modal' className='social-post-likes-modal' isOpen={isOpen} toggle={toggle}>
            <ModalHeader toggle={toggle}>
                <h2 data-testid='reposted-users' className='fw-bold mb-0 text-secondary'>
                    {t('socialPost.users')}
                </h2>
            </ModalHeader>

            <ModalBody>
                {dataLoading && page === 1 ? (
                    <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={30} />
                ) : (
                    <Box
                        sx={{ height: '100%', position: 'relative', overflow: 'auto', maxWidth: '1200px', margin: '0 auto' }}
                        onScroll={handleScroll}
                    >
                        {repostedUsersData.map((repostedUsers: any, index: number) => renderRepostedUsers(repostedUsers, index))}
                        {dataLoading && page !== 1 && <SpinnerLoad className='d-flex align-items-center justify-content-center mt-3' size={30} />}
                    </Box>
                )}
            </ModalBody>

            <ModalFooter>
                <Button data-testid='done-btn' className='w-100' onClick={toggle}>
                    {t('feed.done')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

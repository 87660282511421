import React from 'react';
import { Box, Typography, SxProps, TypographyProps } from '@mui/material';

export type IconTextProps = {
    icon: string | React.ReactNode | string[] | React.ReactNode[];
    text: string;
    IconTextContainerSx?: SxProps;
    textProps?: TypographyProps;
    iconStyle?: React.CSSProperties;
    textStyle?: React.CSSProperties;
    children?: React.ReactNode;
    handleClick?: (e: any) => void;
};

export const IconText = (props: IconTextProps) => {
    const { icon, text, iconStyle, textProps, IconTextContainerSx, children, handleClick } = props;

    const renderIcon = (icon: string | React.ReactNode) => {
        return typeof icon === 'string' ? (
            <span
                className={icon}
                style={{
                    lineHeight: '1.5',
                    fontSize: '16px',
                    width: '16px',
                    textAlign: 'center',
                    fontWeight: 400,
                    ...iconStyle,
                }}
                data-testid='icon-text-icon'
            />
        ) : (
            icon
        );
    };

    return (
        <Box
            sx={{
                display: 'flex',
                gap: '10px',
                ...IconTextContainerSx,
            }}
            data-testid='icon-text'
            onClick={handleClick}
        >
            {typeof icon === 'object' && Array.isArray(icon)
                ? icon?.map((individualIcon: string | React.ReactNode) => renderIcon(individualIcon))
                : renderIcon(icon)}

            <Typography
                {...textProps}
                sx={{
                    fontSize: '16px',
                    lineHeight: '1.5',
                    fontWeight: 400,
                    ...textProps?.sx,
                }}
                data-testid='icon-text-text'
            >
                {text}
                {children}
            </Typography>
        </Box>
    );
};

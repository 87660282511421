export default {
  "sharePost": "Inizia a condividere...",
  "what do you have to say": "Cosa hai da dire",
  "emptyField": "Impossibile salvare un post social vuoto.",
  "socialPost": "Post social",
  "postedFail": "Impossibile pubblicare post sui social, riprova più tardi.",
  "mediaFail": "Si è verificato un errore durante il caricamento dei file multimediali, riprova più tardi.",
  "close": "Chiudere",
  "mediaTooBig": "Alcuni file superavano la dimensione massima e non potevano essere caricati. La dimensione massima del file è",
  "mentionUsers": "Menziona gli utenti",
  "mentionUsersFail": "Impossibile recuperare le menzioni degli utenti, riprova più tardi.",
  "userUnavailable": "Utente non disponibile",
  "postUnavailable": "Ops! Non riusciamo a trovare il post che stai cercando.",

  "you": "Lei",
  "single_you": "Lei",
  
  "you_reposted_this": "ha ripubblicato questo",
  "singular_reposted_this": "ha ripubblicato questo",
  "we_reposted_this": "avete ripubblicato questo",
  "they_reposted_this": "hanno ripubblicato questo",

  "and": "e",
  "more": "altri {{dynamicValue}}",
  "users": "Utenti ripubblicati",
  "repostFailMessage": "L'hai già ripubblicato",
  "repost": "Ripubblicare",
  "repostWithYourThoughts": "Ripubblica con i tuoi pensieri",
  "shareYourThoughtsOnThisPost": "Condividi i tuoi pensieri su questo post",
  "repostWithoutThoughts": "Condividi il post di {{dynamicValue}} con i tuoi follower",
  "repostSuccessMessage": "Ripubblicato con successo."
};
import React, { FunctionComponent, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../feedPage/feedPage.scss';
import objectAssign from 'object-assign';
import SpinnerLoad from '../../common/spinnerLoad/spinnerLoad';
import SocialPostItem from '../../feedPage/itemTypes/socialPost/SocialPostItem';
import { useSelector } from 'react-redux';
import { PostTabDetailType } from './contactDetails';
import connectService from '../../../services/connectService';
import { AxiosResponse } from 'axios';

type PostItemProps = {
    selectedInUserId: string;
    scrollIsBottom: boolean;
    postTabDetail: PostTabDetailType;
    setPostTabDetail: React.Dispatch<React.SetStateAction<PostTabDetailType>>;
};
type PostItemModel = {
    id: string;
};
type FeedSocialPost = {
    model: PostItemModel;
    type: string;
    reason: string;
    author: string;
    is_liked?: boolean;
    likes: [];
    created_at_humans: string;
    files: [];
};

const PostsPage: FunctionComponent<PostItemProps> = ({ selectedInUserId, scrollIsBottom, postTabDetail, setPostTabDetail }) => {
    const { t } = useTranslation();
    const tenantInfo = useSelector((state: any) => state.app.tenant);

    useEffect(() => {
        if (postTabDetail.postData.length == 0 && !postTabDetail.isInitialDataLoad) {
            getPostsData(selectedInUserId, postTabDetail.pageNo);
        }
    }, []);

    useEffect(() => {
        if (scrollIsBottom) {
            if (!postTabDetail.nextPageAvailable) return;
            setPostTabDetail((prev) => ({
                ...prev,
                pageNo: postTabDetail.pageNo + 1,
            }));
            getPostsData(selectedInUserId, postTabDetail.pageNo + 1);
        }
    }, [scrollIsBottom]);

    const getPostsData = (userId: string, pageNo: number) => {
        pageNo === 1
            ? setPostTabDetail((prev) => ({
                  ...prev,
                  isPostsLoading: true,
              }))
            : setPostTabDetail((prev) => ({
                  ...prev,
                  isLoadingMore: true,
              }));
        connectService.getPostData(userId, pageNo, (resp: AxiosResponse) => {
            appendPostData(resp.data.data);
            setPostTabDetail((prev) => ({
                ...prev,
                isPostsLoading: pageNo == 1 ? false : prev.isPostsLoading,
                isLoadingMore: pageNo != 1 ? false : prev.isLoadingMore,
                isInitialDataLoad: true,
                nextPageAvailable: resp.data.links.next != null,
            }));
        });
    };

    const appendPostData = (newElem: Array<any>) => {
        setPostTabDetail((prev) => ({
            ...prev,
            postData: [...postTabDetail.postData, ...newElem],
        }));
    };
    const likeContent = (contentType: string, contentId: number, isLiked: boolean) => {
        const newPostData = postTabDetail.postData ? [...postTabDetail.postData] : [];
        newPostData.forEach((item) => {
            if (item.type !== contentType || !item.model || item.model.id != contentId) return;
            item.model.is_liked = isLiked;
            item.model.likes += isLiked ? 1 : -1;
        });
        setPostTabDetail((prev) => ({
            ...prev,
            postData: newPostData,
        }));
    };

    const likePost = (postId: number, isLiked: boolean) => {
        likeContent('post', postId, isLiked);
    };

    const likeRepost = (postId: number, isLiked: boolean) => {
        likeContent('repost', postId, isLiked);
    };

    const updatePost = (post: FeedSocialPost) => {
        const newPostData = [...postTabDetail.postData];
        const item = newPostData.find((p) => (p.type === 'post' || p.type === 'card-p2p' || p.type === 'repost') && p.model.id === post.model.id);
        objectAssign(item, post);
        setPostTabDetail((prev) => ({
            ...prev,
            postData: newPostData,
        }));
    };

    const removeItem = (itemId: number, checkModel: boolean = true) => {
        setPostTabDetail((prev) => ({
            ...prev,
            postData: postTabDetail.postData.filter((item) => {
                if (checkModel && !item.model) return true;
                const dataSrc = checkModel ? item.model : item;
                return dataSrc.id !== itemId;
            }),
        }));
    };

    const removeContentByUser = (userId: number, checkModel: boolean = true) => {
        setPostTabDetail((prev) => ({
            ...prev,
            postData: postTabDetail.postData.filter((item) => {
                if (checkModel && !item.model) return true;
                const dataSrc = checkModel ? item.model.author : item;
                if (item.type === 'post') return dataSrc.id !== userId;
                else return true;
            }),
        }));
    };

    const renderPosts = () => {
        if (postTabDetail.postData.length > 0) {
            const contData: Array<React.ReactElement> = [];
            if (!postTabDetail.postData) return contData;
            postTabDetail.postData.forEach((item, index) => {
                contData.push(
                    <SocialPostItem
                        isLastItem={index === postTabDetail.postData.length - 1}
                        item={item}
                        setLiked={item.type === 'post' ? likePost : likeRepost}
                        setItem={updatePost}
                        removeItem={removeItem}
                        tyBranding={tenantInfo.tyBranding}
                        removeContentByUser={removeContentByUser}
                        repostWithThoughts={true}
                    />
                );
            });
            return contData;
        } else {
            return (
                <div className='details d-block content-container pt-sm'>
                    <div className='custom-spinner content-spinner'></div>
                    <div className='alert alert-primary message-empty-posts  text-center w-50 m-auto mt-20' role='alert'>
                        {t('connect.thereAreNoPost')}
                    </div>
                </div>
            );
        }
    };

    return (
        <>
            {postTabDetail.isPostsLoading ? (
                <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
            ) : (
                <>
                    {renderPosts()}
                    {postTabDetail.isLoadingMore && (
                        <SpinnerLoad className='h-100 w-100 d-flex align-items-center justify-content-center mt-3' size={50} />
                    )}
                </>
            )}
        </>
    );
};

export default PostsPage;

export default {
    "sharePost": "Começar partilha...",
    "what do you have to say": "O que você tem a dizer",
    "emptyField": "Não é possível gravar a mensagem vazia.",
    "socialPost": "Publicação Social",
    "postedFail": "Publicação Social um erro na transferência do ficheiro, por favor tente mais tarde.",
    "mediaFail": "Houve um erro na transferência do ficheiro, por favor tente mais tarde.",
    "close": "Fechar",
    "mediaTooBig": "O tamanho dos ficheiros excedeu o limite máximo para upload. O tamanho máximo é de ",
    "mentionUsers": "Mencionar usuários",
    "mentionUsersFail": "Não foi possível buscar os usuários mencionados. Tente novamente mais tarde.",
    "userUnavailable": "Usuário indisponível",
    "postUnavailable": "Ops! Não conseguimos encontrar a postagem que você está procurando.",

    "you": "Você",
    "single_you": "Você",

    "you_reposted_this": "repostou isso",
    "singular_reposted_this": "repostou isso",
    "we_reposted_this": "repostaram isso",
    "they_reposted_this": "repostaram isso",
   
    "and": "e",
    "more": "mais {{dynamicValue}}",
    "users": "Usuários republicados",
    "repostFailMessage": "Você já repostou isso",
    "repost": "Repostagem",
    "repostWithYourThoughts": "Reposte com seus pensamentos",
    "shareYourThoughtsOnThisPost": "Compartilhe os seus pensamentos nesse post",
    "repostWithoutThoughts": "Compartilhe a postagem de {{dynamicValue}} com seus seguidores",
    "repostSuccessMessage": "Repostado com sucesso."
};
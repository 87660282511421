import React from 'react';
import { Popover, PopoverProps, Box, Typography } from '@mui/material';
import { IconText } from '../iconText/IconText';
import { useDispatch } from 'react-redux';
import socialPostsService from '../../services/socialPostsService';
import { t } from 'i18next';
import { addNotification } from '../../../shared/reducers/notifications/actionTypes';
import { AxiosResponse } from 'axios';
import { useTranslation } from 'react-i18next';

export type SharePopoverProps = {
    popoverProps?: PopoverProps;
    repostHandler?: Function;
    postId: number;
    authorName: string;
    feedData?: Array<any>;
    setFeedData?: Function;
    handleThoughtsClick: ((e: any) => void) | undefined;
    setShareAnchorEl: React.Dispatch<any>;
};

export const SharePopover = (props: SharePopoverProps) => {
    const { popoverProps, postId, authorName, repostHandler, handleThoughtsClick, setShareAnchorEl } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const saveRepostWithoutThoughts = (postId: number) => {
        socialPostsService.saveRepostWithoutThoughts(
            postId,
            (resp: AxiosResponse) => {
                if (repostHandler) {
                    repostHandler([resp.data.data]);
                    popoverProps?.onClose?.({}, 'backdropClick');
                }
                setShareAnchorEl(null);
                dispatch(addNotification({ label: '', text: t('socialPost.repostSuccessMessage'), type: 'success' }));
            },
            () => {
                dispatch(addNotification({ label: '', text: t('socialPost.repostFailMessage'), type: 'danger' }));
            }
        );
    };

    const handleRepost = () => {
        if (postId !== null) {
            saveRepostWithoutThoughts(postId);
        }
    };

    return (
        <Popover
            open={popoverProps?.open || false}
            anchorEl={popoverProps?.anchorEl}
            onClose={() => {}}
            slotProps={{
                paper: {
                    sx: {
                        padding: '15px 20px',
                        borderRadius: '10px',
                    },
                },
                ...popoverProps?.slotProps,
            }}
            {...popoverProps}
            data-testid='share-popover'
        >
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                }}
                data-testid='share-popover-content'
            >
                <IconText
                    IconTextContainerSx={{
                        cursor: 'pointer',
                    }}
                    icon='fa-solid fa-thought-bubble'
                    text={t('socialPost.repostWithYourThoughts')}
                    iconStyle={{
                        color: '#000000',
                        fontSize: '22px',
                        lineHeight: '1.2',
                    }}
                    textProps={{
                        sx: {
                            fontSize: '16px',
                            fontWeight: 600,
                            color: '#000000',
                            marginLeft: '5px',
                        },
                    }}
                    handleClick={handleThoughtsClick}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#000000',
                        }}
                    >
                        {t('socialPost.shareYourThoughtsOnThisPost')}
                    </Typography>
                </IconText>
                <IconText
                    IconTextContainerSx={{
                        cursor: 'pointer',
                    }}
                    icon='fa-regular fa-arrows-retweet'
                    text={t('socialPost.repost')}
                    iconStyle={{
                        color: '#000000',
                        fontSize: '22px',
                        lineHeight: '1.2',
                    }}
                    textProps={{
                        sx: {
                            fontSize: '16px',
                            fontWeight: 600,
                            color: '#000000',
                            marginLeft: '5px',
                        },
                    }}
                    handleClick={handleRepost}
                >
                    <Typography
                        sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: '#000000',
                        }}
                    >
                        {t('socialPost.repostWithoutThoughts', { dynamicValue: authorName })}
                    </Typography>
                </IconText>
            </Box>
        </Popover>
    );
};

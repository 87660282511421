import React, { useCallback, useState } from 'react';
import { Grid, GridProps, Box } from '@mui/material';
import { ChatImageModal } from '../chatImageModal/chatImageModal';
import { MessageFiles } from '../../../types/interface/MessageFiles';

export type MediaContainerProps = {
    media: MessageFiles[];
    mediaContainerProps?: GridProps;
    displayImagePreview?: boolean;
    objectFit?: 'cover' | 'contain';
};

export const MediaContainer = (props: MediaContainerProps) => {
    const { media, mediaContainerProps, displayImagePreview, objectFit } = props;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(0);
    const [currentImageUrl, setCurrentImageUrl] = useState<string>('');

    const renderIndividualRow = (media: any[], itemProps?: GridProps, displayPlaceholderNumber?: number) => {
        return (
            <>
                {media.map((mediaItem, index) => {
                    const imgPreviewUrl = mediaItem?.type === 'image' ? mediaItem?.path : mediaItem?.preview;
                    return (
                        <Grid item xs={12} height={'100%'} {...itemProps} key={index}>
                            <img
                                src={imgPreviewUrl}
                                alt={mediaItem.name}
                                height={'100%'}
                                width={'100%'}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    displayImagePreview && setIsOpen(true);
                                    setCurrentImageIndex(index);
                                    setCurrentImageUrl(imgPreviewUrl);
                                }}
                                style={{
                                    objectFit: objectFit ? objectFit : 'cover',
                                    cursor: displayImagePreview ? 'pointer' : 'auto',
                                    filter: displayPlaceholderNumber && index === 1 ? 'brightness(0.5)' : 'none',
                                }}
                            />
                            {displayPlaceholderNumber && index === 1 && (
                                <Box
                                    sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                        color: 'white',
                                        fontSize: '24px',
                                    }}
                                >
                                    +{displayPlaceholderNumber}
                                </Box>
                            )}
                        </Grid>
                    );
                })}
            </>
        );
    };

    const renderMultipleMedia = useCallback(() => {
        switch (media.length) {
            case 5:
                return (
                    <>
                        <Grid item xs={12} width={'100%'} height={'50%'}>
                            <Grid container spacing={0.5} direction={'row'} height={'100%'} sx={{ flex: 1 }}>
                                {renderIndividualRow([media[0], media[1], media[2]], { xs: 4 })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} width={'100%'} height={'50%'}>
                            <Grid container spacing={0.5} direction={'row'} height={'100%'} sx={{ flex: 1 }}>
                                {renderIndividualRow([media[3], media[4]], { xs: 6, pt: '0px !important' })}
                            </Grid>
                        </Grid>
                    </>
                );
            case 4:
                return (
                    <>
                        <Grid item xs={12} width={'100%'} height={'50%'}>
                            <Grid container spacing={0.5} direction={'row'} height={'100%'} sx={{ flex: 1 }}>
                                {renderIndividualRow([media[0], media[1]], { xs: 6 })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} width={'100%'} height={'50%'}>
                            <Grid container spacing={0.5} direction={'row'} height={'100%'} sx={{ flex: 1 }}>
                                {renderIndividualRow([media[2], media[3]], { xs: 6, pt: '0px !important' })}
                            </Grid>
                        </Grid>
                    </>
                );
            case 3:
                return (
                    <>
                        {renderIndividualRow([media[0]], { xs: 8 })}
                        <Grid item xs={4} width={'100%'} height={'100%'} sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Grid container spacing={0.5} direction={'column'} height={'100%'} sx={{ flex: 1 }}>
                                {renderIndividualRow([media[1], media[2]], { xs: 6, height: '50%' })}
                            </Grid>
                        </Grid>
                    </>
                );
            case 2:
                return renderIndividualRow(media, { xs: 6 });
            case 1:
                return renderIndividualRow(media, { xs: 12 });
            default:
                return (
                    <>
                        <Grid item xs={12} width={'100%'} height={'50%'}>
                            <Grid container spacing={0.5} direction={'row'} height={'100%'} sx={{ flex: 1 }}>
                                {renderIndividualRow([media[0], media[1], media[2]], { xs: 4 })}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} width={'100%'} height={'50%'}>
                            <Grid container spacing={0.5} direction={'row'} height={'100%'} sx={{ flex: 1 }}>
                                {renderIndividualRow([media[3], media[4]], { xs: 6, position: 'relative', pt: '0px !important' }, media.length - 5)}
                            </Grid>
                        </Grid>
                    </>
                );
        }
    }, [media]);

    return (
        <Grid container data-testid='media-container' spacing={0.5} width={'100%'} height={'100%'} {...mediaContainerProps}>
            {media.length >= 1 && renderMultipleMedia()}
            <ChatImageModal
                isOpen={isOpen}
                image_url={media[currentImageIndex].type === 'video' ? media[currentImageIndex].path : currentImageUrl}
                handleClose={() => setIsOpen(false)}
                files={media}
                imageIndex={currentImageIndex}
            />
        </Grid>
    );
};

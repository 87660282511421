import React, { useState } from 'react';
import { Grid, Box } from '@mui/material';
import { SearchField } from '../../../../components/searchField/searchField';
import { useSearchHook } from '../../../../hooks/useSearchHook';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useLocationsQuery } from '../../../../hooks/query/useLocationsQuery';
import { useDebounce } from '../../../../hooks/useDebounceHook';
import Scrollbar from '../../../../components/common/scrollbar/scrollbar';
import { LocationList } from '../../../../modules/LocationList/LocationList';
import SpinnerLoad from '../../../../components/common/spinnerLoad/spinnerLoad';
import LocationsDetailsPage from '../../../../components/connectPage/locationPage/locationDetailsPage';
import { useNavigate } from 'react-router-dom';
import { NoResultsWidget } from '../../../../components/noResultWidget/noResultWidget';
import { AlphabetList } from '../../../../components/alphabetList/alphabetList';

export const Locations = () => {
    const { searchQuery, handleChange: handleSearch } = useSearchHook('');
    const { t } = useTranslation();
    const { id: locationID } = useParams();
    const { debouncedValue: debouncedSearchValue } = useDebounce(searchQuery);
    const [alphabetFilter, setAlphabetFilter] = useState<string>('');
    const {
        data: locations,
        isLoading: loadingLocations,
        fetchNextPage,
        isFetchingNextPage,
    } = useLocationsQuery({
        searchQuery: debouncedSearchValue,
        prefix: alphabetFilter,
    });
    const navigate = useNavigate();

    const handleScroll = (e: any) => {
        let bottom = Math.round(e.target.scrollTop + e.target.clientHeight) + 1 >= e.target.scrollHeight;
        if (bottom && !isFetchingNextPage) {
            fetchNextPage();
        }
    };

    const handleAlphabetClick = (e: React.MouseEvent<HTMLDivElement>) => {
        const char = e.currentTarget.getAttribute('data-char') || '';
        setAlphabetFilter((prev: string) => (prev === char ? '' : char));
    };

    const handleLocationCardClick = (id: number) => {
        navigate(`/connect/locations/${id}`);
    };

    return (
        <Grid container data-testid='locations-page' sx={{ margin: 0, flexDirection: 'column', gap: '15px' }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12} xl={4} sx={{ paddingRight: locationID ? '14px' : '4px' }}>
                    <SearchField
                        searchQuery={searchQuery}
                        handleSearch={handleSearch}
                        placeholder={t('connect.search') as string}
                        sx={{
                            backgroundColor: 'rgba(255, 255, 255, 1)',
                            borderRadius: '8px',
                            border: '1px solid rgba(0, 0, 0, 0.2)',
                            height: '35px',
                        }}
                        iconStyle={{
                            padding: '0 10px',
                            fontSize: '16px',
                        }}
                    />
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                {locations?.pages?.[0].data.length === 0 && !loadingLocations ? (
                    <Grid item xs={12} sx={{ height: '65vh', display: 'flex', justifyContent: 'center' }}>
                        <NoResultsWidget
                            title={t('connect.noResultsFound')}
                            containerSx={{
                                flexGrow: 1,
                            }}
                            description={
                                t(
                                    debouncedSearchValue ? 'connect.noSearchResultsLocationDescription' : 'connect.noLocationResultsDescription'
                                ) as string
                            }
                            descriptionSx={{
                                textAlign: 'center',
                                maxWidth: '450px',
                            }}
                        />
                        <AlphabetList
                            containerProps={{
                                sx: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    height: '75vh',
                                    alignItems: 'center',
                                    paddingRight: '10px',
                                    margin: '5px 0 0',
                                },
                            }}
                            selectedAlphabet={alphabetFilter}
                            handleClick={handleAlphabetClick}
                        />
                    </Grid>
                ) : (
                    <>
                        <Grid item xs={locationID ? 4 : 12} sx={{ display: 'flex' }}>
                            <Box
                                sx={{ height: '75vh', flexGrow: '1', overflow: 'hidden', overflowY: 'scroll', paddingBottom: '5px' }}
                                onScroll={handleScroll}
                            >
                                <Grid container spacing={2} data-testid='location-list' sx={{ paddingRight: '16px' }}>
                                    <LocationList
                                        locations={locations}
                                        loading={loadingLocations}
                                        containerProps={{
                                            xs: 12,
                                            md: locationID ? 12 : 6,
                                            lg: locationID ? 12 : 4,
                                        }}
                                        handleLocationCardClick={handleLocationCardClick}
                                    />
                                    <Grid item xs={12}>
                                        {isFetchingNextPage && <SpinnerLoad className='my-2' />}
                                    </Grid>
                                </Grid>
                            </Box>
                            <AlphabetList
                                containerProps={{
                                    sx: {
                                        display: 'flex',
                                        flexDirection: 'column',
                                        height: '75vh',
                                        alignItems: 'center',
                                        paddingRight: '10px',
                                        margin: '5px 0 0',
                                    },
                                }}
                                selectedAlphabet={alphabetFilter}
                                handleClick={handleAlphabetClick}
                            />
                        </Grid>
                        {locationID && (
                            <Grid item xs={8} data-testid='location-details-page'>
                                <LocationsDetailsPage displayLocationList={false} />
                            </Grid>
                        )}
                    </>
                )}
            </Grid>
        </Grid>
    );
};
